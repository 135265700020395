import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
import { getDriverQuickWithdrawListServ, updateQuickWithdrawServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import NoRecordFound from "../../components/NoRecordFound"
function DriverQuickWithdraw() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },

    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Weekly Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Quick Withdraw",
      path: "/driver-quick-withdraw",
    },
    {
      name: "Transaction Charge",
      path: "/driver-transaction-charge",
    },
  ];
  const [list, setList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const getDriverQuickWithdrawList = async () => {
    if (list?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await getDriverQuickWithdrawListServ();
      if (response?.data?.statusCode == "200") {
        setList(response?.data?.data);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
    setShowSkelton(false);
  };
  useEffect(() => {
    getDriverQuickWithdrawList();
  }, []);
  const handleUpdateStatus = async (formData)=>{
    try {
      let response = await updateQuickWithdrawServ(formData);
      if(response?.data?.statusCode=="200"){
        toast.success(response?.data?.message);
        getDriverQuickWithdrawList()
      }
      else{
        toast?.error(response?.data?.error)
      }
    } catch (error) {
      toast.error("Internal Server Error")
    }
  }
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#043B64"
          divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="Driver Withdraw"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Quick Withdraw" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#f2fbff" }}>
            <div style={{ margin: "20px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">Transfer Amount</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Transaction Fee</th>

                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? [1, 2, 3, 4, 5, 6, 7]?.map((v, i) => {
                        return (
                          <tr>
                            <td>
                              <Skeleton width={40} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        );
                      })
                    : list?.map((v, i) => {
                        return (
                          <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{v?.driver_details?.first_name}</td>
                            <td>{v?.driver_details?.last_name}</td>
                            <td>${v?.driver_details?.wallet_balance}</td>
                            <td>${v?.transfer_amount}</td>
                            <td>{moment(v.time).format("DD/MM/YYYY")}</td>
                            <td>{moment(v?.time).format("hh:mm A")}</td>
                            <td>${v?.transfer_fee}</td>

                            <td className="d-flex justify-content-center align-items-center">
                              <select className="shadow" value={v?.status} onChange={(e)=>handleUpdateStatus({id:v?.id, status:e.target.value})} style={{background:"#fff", color:v?.status==0 ?"#000":v?.status==1? "#139F01":"red"}}>
                                <option value="0">Action</option>
                                <option value="1">Accept</option>
                                <option value="-1">Reject</option>
                              </select>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {list.length == 0 && !showSkelton && (
               <NoRecordFound/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default DriverQuickWithdraw;
