import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNav from "../../../components/TopNav";
import TableNav from "../../../components/TableNav";
import { getOutOfAreaListServ } from "../../../services/bookingDashboard.services";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGlobalState } from "../../../GlobalProvider";
import NoRecordFound from "../../../components/NoRecordFound"
function OutOfArea() {
  
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Sharing",
      path: "/sharing-group-booking",
    },
    {
      name: "Personal",
      path: "/personal-new-booking",
    },
    {
      name: "Airport",
      path: "/airport-comming-soon",
    },
    {
      name: "Drive Test",
      path: "/drive-test-new-booking",
    },
    {
      name: "Intercity",
      path: "/intercity-comming-soon",
    },
    {
      name: "Driver's Availability",
      path: "/availability-new-booking",
    },
    {
      name: "Driver's Route",
      path: "/route-new-booking",
    },
    {
      name: "Out Of Area",
      path: "/out-of-area",
    },
  ];
  const tableNav = [
    {
      name: "New Booking",
      path: "/sharing-new-booking",
    },
    {
      name: "Confirmed",
      path: "/sharing-confirmed-booking",
    },
    {
      name: "Group",
      path: "/sharing-group-booking",
    },
    {
      name: "Assigned",
      path: "/sharing-assigned-booking",
    },
    {
      name: "Accepted",
      path: "/sharing-accepted-booking",
    },
    {
      name: "Enroute",
      path: "/sharing-enroute-booking",
    },
    {
      name: "Completed",
      path: "/sharing-completed-booking",
    },
    {
      name: "Cancelled",
      path: "/sharing-cancelled-booking",
    },
  ];
  const [outOfAreaList, setOutOfAreaList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const getOutOfAreaFunc = async () => {
    if (outOfAreaList?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await getOutOfAreaListServ({});
      if (response?.data?.statusCode == "200") {
        setOutOfAreaList(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    getOutOfAreaFunc();
  }, []);
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Booking Dashboard" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout"  style={{minWidth: "1350px", marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#000"
          divideRowClass="col-xl-6 col-lg-6 col-md-12 col-12"
          selectedItem="Out Of Area"
          sectedNavBg="#FFFD82"
          selectedNavColor="#000"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#363435" }}>
            <div style={{ margin: "20px 10px" }}>
              <table className="table bookingTable">
                <thead>
                  <tr style={{ background: "#FFFD82", color: "#000" }}>
                    <th scope="col" style={{ borderRadius: "24px 0px 0px 24px" }}>
                      <div className="d-flex justify-content-center ms-2">
                        <span>Sr. No</span>
                      </div>
                    </th>
                    <th scope="col">Source Address</th>
                    <th scope="col">Destination Address</th>
                    <th scope="col">Username</th>
                    <th scope="col">Category</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Date</th>
                    <th scope="col" style={{ borderRadius: "0px 24px 24px 0px" }}>
                      Time
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        );
                      })
                    : outOfAreaList?.map((v, i) => {
                        return (
                          <>
                            <tr className="bg-light mb-2">
                              <td scope="row" style={{ borderTopLeftRadius: "24px", borderBottomLeftRadius: "24px" }}>
                                {i + 1}
                              </td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  <div
                                    style={{
                                      padding: "3px 6px",

                                      borderRadius: "8px",
                                      width: "220px",
                                      wordWrap: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {v?.source}
                                  </div>
                                </div>{" "}
                              </td>
                              <td>
                              
                                <div className="d-flex justify-content-center">
                                  <div
                                    style={{
                                      padding: "3px 6px",
                                      borderRadius: "8px",
                                      width: "220px",
                                      wordWrap: "break-word",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {v?.destination}
                                  </div>
                                </div>{" "}
                              </td>
                              <td>{v?.user_details?.first_name}</td>
                              <td>
                                <div>
                                  <button className="btn btn-success" style={{ background: "#017512" }}>
                                    {v?.category_details?.name}
                                  </button>
                                </div>
                              </td>

                              <td>
                                {v?.user_details?.country_code} {v?.user_details?.contact}
                              </td>
                              <td>{moment(v?.created_at).format("DD/MM/YYYY")}</td>

                              <td
                                style={{
                                  borderTopRightRadius: "24px",
                                  borderBottomRightRadius: "24px",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ borderRadius: "12px", width: "100%", height: "100%" }}
                                >
                                  {moment(v?.created_at).format("hh:mm A")}
                                </div>
                              </td>
                            </tr>
                            <div className="py-2"></div>
                          </>
                        );
                      })}
                </tbody>
              </table>
              {outOfAreaList?.length == 0 && !showSkelton && (
                <NoRecordFound theme="light"/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default OutOfArea;
