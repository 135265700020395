import React from "react";
import {useNavigate} from "react-router-dom"
function TopNav({navBg,isItemMoreThen8, navItems, divideRowClass, sectedNavBg, selectedItem, selectedNavColor, navColor }) {
  const navigate = useNavigate()
  if(isItemMoreThen8){
    return (
      <nav className="topNavMain " style={{background:navBg}}>
        <div className="row m-0 p-1 ">
          <div className= { "col-4 row m-0 p-0"}>
            {navItems?.slice(0, 3)?.map((v, i) => {
              return (
                <div className="col-lg-4  m-0 p-0">
                  <div onClick={()=>navigate(v?.path)} className= " navItem" style={{background :v?.name==selectedItem ? sectedNavBg :""}}>
                    <p className="mb-0 " style={{color : v?.name==selectedItem ? selectedNavColor:navColor }}>{v?.name}</p>
                  </div>
                </div> 
              );
            })}
            
          </div>
          <div className= { "col-4 row m-0 p-0 "}>
            {navItems?.slice(3, 6)?.map((v, i) => {
              return (
                <div className="col-lg-4  m-0 p-0">
                  <div onClick={()=>navigate(v?.path)} className= " navItem" style={{background :v?.name==selectedItem ? sectedNavBg :""}}>
                    <p className="mb-0 " style={{color : v?.name==selectedItem ? selectedNavColor:navColor }}>{v?.name}</p>
                  </div>
                </div> 
              );
            })}
            
          </div>
          <div className= {"col-4 row m-0 p-0 "}>
            {navItems?.slice(6, 9)?.map((v, i) => {
              return (
                <div className="col-lg-4  m-0 p-0">
                  <div onClick={()=>navigate(v?.path)} className= " navItem" style={{background :v?.name==selectedItem ? sectedNavBg :""}}>
                    <p className="mb-0 " style={{color : v?.name==selectedItem ? selectedNavColor:navColor }}>{v?.name}</p>
                  </div>
                </div> 
              );
            })}
            
          </div>
        </div>
      </nav>
    );
  }else{
    return (
      <nav className="topNavMain " style={{background:navBg}}>
        <div className="row m-0 p-1 ">
          <div className= {divideRowClass +" row m-0 p-0"}>
            {navItems?.slice(0, 4)?.map((v, i) => {
              return (
                <div className="col-lg-3  m-0 p-0">
                  <div onClick={()=>navigate(v?.path)} className= " navItem" style={{background :v?.name==selectedItem ? sectedNavBg :""}}>
                    <p className="mb-0 " style={{color : v?.name==selectedItem ? selectedNavColor:navColor }}>{v?.name}</p>
                  </div>
                </div> 
              );
            })}
            
          </div>
          <div className= {divideRowClass +" row m-0 p-0"}>
            {navItems?.slice(4, 8)?.map((v, i) => {
              return (
                <div className="col-lg-3  m-0 p-0">
                  <div onClick={()=>navigate(v?.path)} className= " navItem" style={{background :v?.name==selectedItem ? sectedNavBg :""}}>
                    <p className="mb-0 " style={{color : v?.name==selectedItem ? selectedNavColor:navColor }}>{v?.name}</p>
                  </div>
                </div> 
              );
            })}
            
          </div>
        </div>
      </nav>
    );
  }
  
}

export default TopNav;
