import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import axios from "axios";
import { addSharingPriceServ, listProvinceServ, sharingLocationListServ } from "../../services/priceAndCity.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import { useGlobalState } from "../../GlobalProvider";
import LocationAutoComplete from '../../components/LocationAutoComplete';
function SharingLocation() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Categories",
      path: "/pricing-categories",
    },
    {
      name: "Province",
      path: "/pricing-province",
    },
    {
      name: "Location",
      path: "/pricing-sharing-location",
    },
    {
      name: "Surges",
      path: "/pricing-sharing-surges",
    },
    {
      name: "Commission",
      path: "/pricing-commission",
    },
    
    {
      name: "Interac Id",
      path: "/pricing-iterac-id",
    },
    {
      name: "Payout Info",
      path: "/pricing-payout-info",
    },
    {
      name: "Cancel",
      path: "/pricing-cancel",
    },
  ];
  const tableNav = [
    {
      name: "Sharing",
      path: "/pricing-sharing-location",
    },
    {
      name: "Personal",
      path: "/pricing-personal-location",
    },
    {
      name: "To Airport",
      path: "/pricing-to-airport",
    },
    {
      name: "From Airport",
      path: "/pricing-from-airport",
    },
    {
      name: "Drive Test",
      path: "/pricing-drive-test",
    },
    {
      name: "Intercity",
      path: "/pricing-intercity",
    },
  ];
  
  const [formData, setFormData] = useState({
    category_id: 1,
    source: "",
    destination: "",
    sharing_price: "",
    source_long: "",
    source_lat: "",
    destination_long: "",
    destination_lat: "",
    is_swap_data: false,
  });
  const handleSubmitStorePrice = async () => {
    try {
      let response = await addSharingPriceServ({ ...formData, is_swap_data: formData?.is_swap_data ? 1 : 0 });
      if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        setFormData({
          category_id: 1,
          source: "",
          destination: "",
          sharing_price: "",
          source_long: "",
          source_lat: "",
          destination_long: "",
          destination_lat: "",
          is_swap_data: false,
        });
        handleGetProvinceListFunc();
      }
    } catch (error) {}
  };
  const [showSkelton, setShowSkelton] = useState(false);
  const [provinceList, setProvienceList] = useState([]);
  const handleGetProvinceListFunc = async () => {
    if (provinceList?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await listProvinceServ();
      if (response?.data?.statusCode == "200") {
        setProvienceList(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  const [showListSkelton, setShowListSkelton] = useState(false);
  const [sharingPriceList, setConstSharingPriceList] = useState([]);
  const handleGetSharingLocationFunc = async () => {
    if (sharingPriceList?.length == 0) {
      setShowListSkelton(true);
    }
    try {
      let response = await sharingLocationListServ({ category_id: 1 });
      if (response?.data?.statusCode == "200") {
        setConstSharingPriceList(response?.data?.data);
      }
    } catch (error) {}
    setShowListSkelton(false);
  };
  useEffect(() => {
    handleGetProvinceListFunc();
    handleGetSharingLocationFunc();
  }, []);
  const setSourceLocationFunc = (obj)=>{
    setFormData({
      ...formData,
      source: obj.cityName,
      source_long: obj.lng,
      source_lat: obj.lat,
    });
  }
  const setDestinationLocationFunc = (obj)=>{
    setFormData({
      ...formData,
      destination:  obj.cityName,
      destination_long: obj.lng,
      destination_lat: obj.lat,
    });
  }
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Pricing & Cities" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#035791"
          divideRowClass="col-xl-6 col-lg-6 col-md-6 col-6"
          selectedItem="Location"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          isItemMoreThen8={true}
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Sharing" sectedItemBg="#F3F3F3" />
          <div className="tableBody py-2 px-4 borderRadius50exceptTopLeft" style={{ background: "#F3F3F3" }}>
            <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-8 row m-0 p-0">
                <div className="col-lg-4  m-0 ">
                  {/* <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <input
                      onChange={(e) => handleSourceSearch(e.target.value)}
                      value={sourceQuery}
                      readOnly={formData?.source}
                      placeholder="Search Source"
                      style={{ width: "100%" }}
                    />
                    {formData?.source ? (
                      <i
                        className="fa fa-close"
                        onClick={() => {
                          setFormData({ ...formData, source_long: "", source_lat: "", source: "" });
                          setSourceQuery("");
                          setSourceLocation([]);
                        }}
                      ></i>
                    ) : (
                      <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                    )}
                  </div> */}
                  <LocationAutoComplete placeholder="Search Source" callBackFunc={setSourceLocationFunc}/>
                  {/* {sourceLocation.length > 0 && formData?.source === "" && (
                    <ul className="priceCityUl shadow-sm">
                      {sourceLocation.map((v, i) => {
                        // Try to find the component with "administrative_area_level_3"
                        let stateComponent = v?.address_components.find((component) =>
                          component.types.includes("administrative_area_level_3")
                        );

                        // If not found, look for the component with "locality"
                        if (!stateComponent) {
                          stateComponent = v?.address_components.find((component) =>
                            component.types.includes("locality")
                          );
                        }

                        // Only render if a valid component is found
                        return stateComponent ? (
                          <li
                            onClick={() => {
                              setSourceQuery(stateComponent.long_name);
                              console.log(stateComponent);
                              setFormData({
                                ...formData,
                                source: stateComponent.long_name,
                                source_long: v?.geometry?.location.lng,
                                source_lat: v?.geometry?.location.lat,
                              });
                            }}
                            key={i}
                          >
                            {stateComponent.long_name}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )} */}
                </div>
                <div className="col-lg-4 m-0">
                  {/* <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <input
                      onChange={(e) => handleDestinationSearch(e.target.value)}
                      value={destinationQuery}
                      readOnly={formData?.destination}
                      placeholder="Search Source"
                      style={{ width: "100%" }}
                    />
                    {formData?.destination ? (
                      <i
                        className="fa fa-close"
                        onClick={() => {
                          setFormData({ ...formData, destination_long: "", destination_lat: "", destination: "" });
                          setDestinationQuery("");
                          setDestinationLocation([]);
                        }}
                      ></i>
                    ) : (
                      <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                    )}
                  </div>
                  {destinationLocation.length > 0 && formData?.destination === "" && (
                    <ul className="priceCityUl shadow-sm">
                      {destinationLocation.map((v, i) => {
                        // Try to find the component with "administrative_area_level_3"
                        let stateComponent = v?.address_components.find((component) =>
                          component.types.includes("administrative_area_level_3")
                        );

                        // If not found, look for the component with "locality"
                        if (!stateComponent) {
                          stateComponent = v?.address_components.find((component) =>
                            component.types.includes("locality")
                          );
                        }

                        // Only render if a valid component is found
                        return stateComponent ? (
                          <li
                            onClick={() => {
                              setDestinationQuery(stateComponent.long_name);
                              setFormData({
                                ...formData,
                                destination: stateComponent.long_name,
                                destination_long: v?.geometry?.location.lng,
                                destination_lat: v?.geometry?.location.lat,
                              });
                            }}
                            key={i}
                          >
                            {stateComponent.long_name}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  )} */}
                  <LocationAutoComplete placeholder="Search Destination" callBackFunc={setDestinationLocationFunc}/>
                </div>
                <div className="col-lg-3 m-0 ">
                  <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <p className="mb-0 me-2">$</p>
                    <input
                      value={formData?.sharing_price}
                      placeholder="Enter Price"
                      style={{ width: "100%" }}
                      type="number"
                      onChange={(e) => setFormData({ ...formData, sharing_price: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 row m-0 p-0">
                <div className="col-lg-6  m-0 ">
                  <div className="d-flex justify-content-end align-items-center ">
                    <div
                      className="customSelect d-flex justify-content-center align-items-center"
                      onClick={() => setFormData({ ...formData, is_swap_data: !formData?.is_swap_data })}
                    >
                      {formData?.is_swap_data && <img src="https://cdn-icons-png.flaticon.com/128/447/447147.png" />}
                    </div>
                    <button
                      className="btn btn-success  bgSuccess"
                      style={{ border: "none", background: "#000000" }}
                      onClick={() => setFormData({ ...formData, is_swap_data: !formData?.is_swap_data })}
                    >
                      Swap
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 m-0 ">
                  <div className="d-flex justify-content-between align-items-center ">
                    <button
                      className="btn btn-success w-100 bgSuccess"
                      style={{ border: "none" }}
                      onClick={handleSubmitStorePrice}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="provienceListMain">
              <div className="row-8-cols">
                {showSkelton
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((v, i) => {
                      return (
                        <div className="col-custom-8">
                          <Skeleton width={140} height={100} />
                        </div>
                      );
                    })
                  : provinceList?.map((v, i) => (
                      <div className="col-custom-8" key={i}>
                        <div className="provienceItem d-flex justify-content-center">
                          <div className="text-center">
                            <img
                              src="https://cdn-icons-png.flaticon.com/128/535/535188.png"
                              alt="province icon"
                              style={{ width: "40px", height: "40px" }} // Adjust size if needed
                            />
                            <p className="my-1">{v?.name}</p>
                            <p className="mb-0">0</p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>

            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">Source</th>
                    <th scope="col">Destination</th>
                    <th scope="col">Price</th>

                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showListSkelton
                    ? Array.from({ length: 5 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={80} />
                          </td>

                          <td>
                            <Skeleton width={140} />
                          </td>
                          <td>
                            <Skeleton width={140} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>

                          <td>
                            <Skeleton width={80} />
                          </td>
                        </tr>
                      ))
                    : sharingPriceList?.map((v, i) => {
                        return (
                          <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{v?.source}</td>
                            <td>{v?.destination}</td>

                            <td>${v?.sharing_price}</td>

                            <td className="d-flex justify-content-center align-items-center">
                              <select onChange={() => alert("Working on it")}>
                                <option>Edit</option>
                                <option>Delete</option>
                              </select>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default SharingLocation;
