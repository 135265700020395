import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
import { getDriverApprovedListServ, driverWeeklyWithdrawByAdminServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import NoRecordFound from "../../components/NoRecordFound"
function DriverWeeklyWithdraw() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },

    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Weekly Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Quick Withdraw",
      path: "/driver-quick-withdraw",
    },
    {
      name: "Transaction Charge",
      path: "/driver-transaction-charge",
    },
  ];
  const [list, setList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const getApprovedDriverList = async () => {
    if (list?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await getDriverApprovedListServ();
      if (response?.data?.statusCode == "200") {
        const updatedList = response?.data?.data.map((item) => ({
          ...item,
          isEditable: false,
        }));
        setList(updatedList);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
    setShowSkelton(false);
  };
  useEffect(() => {
    getApprovedDriverList();
  }, []);
  const [formData, setFormData] = useState({
    driver_id: "",
    transfer_amount: "",
    transfer_date: "",
    transfer_time: "",
  });
  const handleSubmitFundTransfer = async () => {
    try {
      let response = await driverWeeklyWithdrawByAdminServ(formData);
      if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        getApprovedDriverList();
        setFormData({
          driver_id: "",
          transfer_amount: "",
          transfer_date: "",
          transfer_time: "",
        });
      } else {
        toast.error(response?.data?.message);
        setFormData({
          driver_id: "",
          transfer_amount: "",
          transfer_date: "",
          transfer_time: "",
        });
      }
    } catch (error) {
      toast.error("Internal Server Error");
      setFormData({
        driver_id: "",
        transfer_amount: "",
        transfer_date: "",
        transfer_time: "",
      });
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="Driver Withdraw"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          navBg="#043B64"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Weekly Withdraw" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50exceptTopLeft" style={{ background: "#f2fbff" }}>
            <div style={{ margin: "20px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">Transfer Amount</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>

                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? [1, 2, 3, 4, 5, 6, 7]?.map((v, i) => {
                        return (
                          <tr>
                            <td>
                              <Skeleton width={40} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        );
                      })
                    : list?.map((v, i) => {
                        return (
                          <tr>
                            <td scope="row">{i + 1}</td>
                            <td>{v?.first_name}</td>
                            <td>{v?.last_name}</td>
                            <td>${v?.wallet_balance}</td>
                            <td style={{ width: "200px" }}>
                              <div className=" d-flex justify-content-center">
                                <div className="whiteDivBtn">
                                  <div>
                                    <input
                                      type="number"
                                      placeholder="Enter Amount"
                                      style={{ background: "none", border: "none", outline: "none" }}
                                      readOnly={!v?.isEditable}
                                      onChange={(e) => setFormData({ ...formData, transfer_amount: e.target.value })}
                                      value={formData?.driver_id==v?.id ? formData?.transfer_amount:""}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "200px" }}>
                              <div className=" d-flex justify-content-center">
                                <div className="whiteDivBtn">
                                  <div>
                                    <input
                                      type="date"
                                      style={{ background: "none", border: "none", outline: "none" }}
                                      readOnly={!v?.isEditable}
                                      onChange={(e) => setFormData({ ...formData, transfer_date: e.target.value })}
                                      value={formData?.driver_id==v?.id ? formData?.transfer_date:""}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "200px" }}>
                              <div className=" d-flex justify-content-center">
                                <div className="whiteDivBtn">
                                  <div>
                                    <input
                                      type="time"
                                      step="1" // Adds seconds to the time picker
                                      style={{ background: "none", border: "none", outline: "none" }}
                                      readOnly={!v?.isEditable}
                                      onChange={(e) => {
                                        const value = e.target.value; // e.g., "12:30:45"
                                        const formattedValue = value.length === 5 ? `${value}:00` : value; // Ensure seconds are added if missing
                                        setFormData({ ...formData, transfer_time: formattedValue });
                                      }}
                                      value={formData?.driver_id==v?.id ? formData?.transfer_time:""}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td
                              className="d-flex justify-content-center "
                              style={{ position: "relative", top: "-5px" }}
                            >
                              {!v?.isEditable ? (
                                <button
                                  className="btn btn-dark btn-sm py-2"
                                  style={{ width: "100px", border: "none", background: "#363535" }}
                                  onClick={() => {
                                    const updatedList = list.map((item, index) =>
                                      index === i ? { ...item, isEditable: !v?.isEditable } : {...item, isEditable:false}
                                    );
                                    setList(updatedList);
                                    toast.success("Start Editing the Form");
                                    setFormData({   
                                      transfer_amount: "",
                                      transfer_date: "",
                                      transfer_time: "",driver_id: v?.id });
                                  }}
                                >
                                  Edit
                                </button>
                              ) : formData.driver_id &&
                                formData?.transfer_amount &&
                                formData?.transfer_date &&
                                formData?.transfer_time ? (
                                <button
                                  className="btn btn-success btn-sm py-2"
                                  style={{ width: "100px", border: "none", background: "#139F01" }}
                                  onClick={handleSubmitFundTransfer}
                                >
                                  Pay
                                </button>
                              ) : (
                                <button
                                  className="btn btn-success btn-sm py-2"
                                  style={{ width: "100px", border: "none", background: "#139F01", opacity: "0.6" }}
                                >
                                  Pay
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {list.length == 0 && !showSkelton && (
               <NoRecordFound/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default DriverWeeklyWithdraw;
