import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getUserListServ, updateUserWalletAmountServ, deleteUserServ } from "../../services/user.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
import { toast } from "react-toastify";
import { Image_Base_Url } from "../../utils/api_base_url_configration";
import moment from "moment";
import Ably from 'ably';


function UserList() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [actionValue, setActionValue] = useState("");
  const [payload, setPayload] = useState({
    page: 1,
    search_key: "",
    per_page: 10,
  });
  const [pageData, setPageData] = useState({
    total_pages: "",
    current_page: "",
  });
  const renderPage = () => {
    const pages = [];
    // Generate page numbers
    for (let i = 1; i <= pageData?.total_pages; i++) {
      pages.push(
        <li key={i} className={`page-item`} onClick={() => setPayload({ ...payload, page: i })}>
          <a
            className="page-link"
            href="#"
            style={{
              background: pageData?.current_page === i ? "#024596" : "",
              color: pageData?.current_page === i ? "#fff" : "",
            }}
          >
            {i}
          </a>
        </li>
      );
    }

    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {/* Previous button */}
          {pageData?.total_pages > 1 && pageData?.current_page !=1 && (
            <li className="page-item" onClick={() => setPayload({ ...payload, page: pageData.current_page - 1 })}>
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
          )}

          {/* Page numbers */}
          {pages}

          {/* Next button */}
          {pageData?.total_pages > 1 &&  pageData?.total_pages !=pageData?.current_page && (
            <li className="page-item" onClick={() => setPayload({ ...payload, page: pageData?.current_page + 1 })}>
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    );
  };
  const handleGetUserListFunc = async () => {
    if(userList.length==0){
      setShowSkelton(true);
    }
    try {
      let response = await getUserListServ(payload);
      if (response?.data?.statusCode == "200") {
        const updatedUserList = response?.data?.data.map((item) => ({
          ...item,
          showPassword: false,
        }));
        setUserList(updatedUserList);
        setPageData({
          total_pages: response?.data?.total_pages,
          current_page: response?.data?.current_page,
        });
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    // Initialize Ably client with the API key
    const ably = new Ably.Realtime('cgbtZA.AQetNw:hE5TCgJHH9F4gWbFqv6pD5ScBM-A_RnW0RQG7xwQg-Y');
    const channel = ably.channels.get('user-updates');
  
    // Fetch user list initially
    handleGetUserListFunc();
  
    // Subscribe to the 'user-updates' channel for real-time updates
    channel.subscribe('profile-updated', (message) => {
      console.log("Received real-time update:", message.data);
      // Re-fetch user list when an update is received
      handleGetUserListFunc();
    });
  
    // Cleanup on component unmount
    return () => {
      channel.unsubscribe();
      ably.close();
    };
  }, [payload]); // Re-run when the `payload` changes
  

  const [userHistory, setUserHistory] = useState({
    show: false,
    transection_history: [],
  });
  const [userFunds, setUserFunds] = useState({
    show: false,
    current_wallet: "",
    user_wallet: "",
    user_id: "",
    reason: "",
  });

  const updateWalletFunc = async () => {
    try {
      let response = await updateUserWalletAmountServ(userFunds);
      if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        handleGetUserListFunc();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
    setUserFunds({
      show: false,
      current_wallet: "",
      user_wallet: "",
      user_id: "",
    });
  };
  const handleDeleteUser = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete the record?");
    if (confirmed) {
      try {
        let response = await deleteUserServ(id);
        if (response?.data?.statusCode == "200") {
          toast.success(response?.data?.message);
          setActionValue("");
          handleGetUserListFunc();
        } else if (response?.data?.statusCode == "400") {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error("Internal Server Error");
      }
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="User" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section
        className="section_layout"
        style={{ minWidth: "1850px", marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}
      >
        {/* table List started */}
        <div className="tableMain" style={{ marginTop: "0px" }}>
          <div className="tableBody   ">
            {/* upper section start */}
            <div className="row ms-2 mb-5 p-0">
              <div className="col-5 m-0 p-0">
                <div className="searchBox d-flex">
                  <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                  <input
                    placeholder="Search"
                    onChange={(e) => setPayload({ ...payload, search_key: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-7 m-0 p-0 row">
                <div className="col-4">
                  <div className="filterBox d-flex ">
                    <img src="https://cdn-icons-png.flaticon.com/128/1159/1159641.png" />
                    <select>
                      <option>Filter</option>
                      <option>Filter 1</option>
                      <option>Filter 2</option>
                      <option>Filter 3</option>
                    </select>
                  </div>
                </div>
                <div className="col-2 my-auto">
                  <div className="d-flex justify-content-between showSelectDiv align-items-center">
                    <p className="mb-0">Show</p>
                    <select
                      value={payload.per_page}
                      onChange={(e) => setPayload({ ...payload, per_page: e.target.value })}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </select>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-center my-auto">
                  <div className="justify-content-center align-items-center ">
                    <p className="mb-0 entriesText">Entities</p>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    onClick={() => navigate("/add-user")}
                    className="addUserBtn d-flex justify-content-between align-items-center"
                  >
                    <p className="mb-0">Add User</p>
                    <img src="https://cdn-icons-png.flaticon.com/128/1077/1077114.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* upper section end */}
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#f9f9f9" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      <div className="d-flex justify-content-center ms-2">
                        <span>Sr. No</span>
                      </div>
                    </th>
                    <th scope="col">Profile Picture</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Wallet</th>

                    <th scope="col">Status</th>
                    <th scope="col">Password</th>
                    <th scope="col">History</th>
                    <th scope="col">Funds</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Manage Profile
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? Array(10)
                        .fill()
                        .map((_, i) => (
                          <tr key={i}>
                            <td>
                              <Skeleton width={20} />
                            </td>
                            <td>
                              <Skeleton circle height={50} width={50} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={120} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                          </tr>
                        ))
                    : userList?.map((v, i) => (
                        <tr key={i}>
                          <td scope="row">{i + 1}</td>
                          <td>
                            <img
                              src={
                                v?.image
                                  ? Image_Base_Url + v?.image
                                  : "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                              }
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "59%",
                                cursor: "pointer",
                                marginTop: "-16px",
                              }}
                            />
                          </td>
                          <td>{v?.first_name}</td>
                          <td>{v?.last_name}</td>
                          <td>{v?.email}</td>
                          <td>
                            {v?.country_code} {v?.contact}
                          </td>
                          <td>${parseFloat(v?.user_wallet).toFixed(2)}</td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <div className={"greenCircle me-2"}></div>
                              <select value={"Active"} style={{ background: "#fff", color: "#139f01" }}>
                                <option style={{ color: "green" }}>Active</option>
                                <option style={{ color: "red" }}>Inactive</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div style={{ marginTop: "-10px" }}>
                              <img
                                src={
                                  !v?.showPassword
                                    ? "https://cdn-icons-png.flaticon.com/128/159/159604.png"
                                    : "https://cdn-icons-png.flaticon.com/128/2767/2767146.png"
                                }
                                style={{ height: "14px" }}
                                onClick={() => {
                                  const updatedUserList = userList.map((item, index) =>
                                    index === i ? { ...item, showPassword: !v?.showPassword } : item
                                  );
                                  setUserList(updatedUserList);
                                }}
                              />
                              <div>{v?.showPassword ? v?.password?.substring(0, 6) : "****** "}</div>
                            </div>{" "}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ marginTop: "-6px", background: "#7650E0", borderRadius: "6px", border: "none" }}
                              onClick={() => {
                                setUserHistory({
                                  show: true,
                                  transaction_history: v?.transaction_history,
                                });
                              }}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ marginTop: "-6px", background: "#7650E0", borderRadius: "6px", border: "none" }}
                              onClick={() => {
                                setUserFunds({
                                  show: true,
                                  current_wallet: v?.user_wallet,
                                  user_wallet: v?.user_wallet,
                                  user_id: v?.id,
                                });
                              }}
                            >
                              Open
                            </button>
                          </td>
                          <td>
                            <select
                              style={{ padding: "9.5px", marginTop: "0px" }}
                              value={actionValue}
                              onChange={(e) => {
                                if (e.target.value == "delete") {
                                  handleDeleteUser(v?.id);
                                  setActionValue("");
                                }
                                if (e.target.value == "edit") {
                                  toast.info("Work in progress");
                                  setActionValue("");
                                }
                              }}
                            >
                              <option value="">Action</option>
                              <option value="edit">Edit</option>
                              <option value="delete">Delete</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              {renderPage()}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

      {/* User History Start */}
      {/* Modal */}
      {userHistory?.show && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp"
          tabIndex="-1"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adds a semi-transparent background
            zIndex: 1050,
          }}
        >
          <div
            className="modal-dialog"
            style={{
              width: "70%", // Takes 90% of the screen width
              maxWidth: "100%", // Ensures it doesn't exceed the screen width
              margin: "0 auto",
              maxHeight: "calc(100% - 100px)", // Leaves 50px gap at top and bottom
              margin: "50px auto", // 50px gap from top and bottom
              overflowY: "auto", // Scrollable content when it overflows
            }}
          >
            <div
              className="modal-content"
              style={{
                border: "1px solid #7650E0",
                borderRadius: "9px",
                background: "#f9fafc",
                width: "100%", // Ensures it stretches across the modal dialog
                padding: "20px",
              }}
            >
              <div className="d-flex justify-content-end p-2">
                <i
                  className="fa fa-close text-secondary"
                  onClick={() => {
                    setUserHistory({
                      show: false,
                      transaction_history: [],
                    });
                  }}
                ></i>
              </div>
              <h4 className="mb-4 text-center">History</h4>
              <table className="table">
                <thead className=" rounded" style={{ background: "#FDEEE7" }}>
                  <tr>
                    <th scope="col" style={{ borderRadius: "6px 0px 0px 6px" }}>
                      Sr. No
                    </th>
                    <th scope="col">Old Balance</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Transaction Details</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col" style={{ borderRadius: "0px 6px 6px 0px" }}>
                      New Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userHistory?.transaction_history?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>$ {v?.wallet_amount}</td>
                        <td style={{ color: v?.transfer_type == "debit" ? "#FD0100" : "#139F02" }}>
                          $ {v?.transaction_amount}
                        </td>
                        <td>{v?.type}</td>
                        <td>{moment(v.time).format("DD/MM/YYYY")}</td>
                        <td>{moment(v?.time).format("hh:mm A")}</td>
                        <td>$ {v?.current_balance}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {userHistory?.show && <div className="modal-backdrop fade show"></div>}
      {/* User History end*/}

      {/* User Funds Start */}
      {/* Modal */}
      {userFunds?.show && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp"
          tabIndex="-1"
        >
          <div className="modal-dialog" style={{ width: "370px" }}>
            <div className="modal-content fundsPopUpDiv p-4">
              <div className="d-flex justify-content-end p-2">
                <i
                  className="fa fa-close text-secondary"
                  onClick={() => {
                    setUserFunds({
                      show: false,
                    });
                  }}
                ></i>
              </div>
              <h6 className="mb-4">Funds</h6>
              <div className="modal-body p-0">
                <div className="d-flex justify-content-center">
                  <img src="https://t4.ftcdn.net/jpg/05/55/92/27/240_F_555922705_6ay4h4MnDkyRgTsmVpCEkpzGbKffHTgu.jpg" />
                </div>
                <p className="text-center">Total Balance</p>
                <div className="d-flex justify-content-center">
                  <div className="balanceDiv">${userFunds?.current_wallet}</div>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setUserFunds({ ...userFunds, user_wallet: parseInt(userFunds?.user_wallet) - 1 })}
                  >
                    -
                  </span>
                  <input
                    value={userFunds?.user_wallet}
                    onChange={(e) => setUserFunds({ ...userFunds, user_wallet: e.target.value })}
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setUserFunds({ ...userFunds, user_wallet: parseInt(userFunds?.user_wallet) + 1 })}
                  >
                    +
                  </span>
                </div>
                <div>
                  {userFunds?.current_wallet < userFunds?.user_wallet ? (
                    <select
                      className="form-control"
                      onChange={(e) => setUserFunds({ ...userFunds, reason: e?.target.value })}
                    >
                      <option value="">Select</option>
                      <option value="Reason 1 for adding fund">Reason 1 for adding fund</option>
                      <option value="Reason 2 for adding fund">Reason 2 for adding fund</option>
                      <option value="Reason 3 for adding fund">Reason 3 for adding fund</option>
                      <option value="Reason 3 for adding fund">Reason 4 for adding fund</option>
                    </select>
                  ) : (
                    <select
                      className="form-control"
                      onChange={(e) => setUserFunds({ ...userFunds, reason: e?.target.value })}
                    >
                      <option value="">Select</option>
                      <option value="Reason 2 for decreasing fund">Reason 1 for decreasing fund</option>
                      <option value="Reason 2 for decreasing fund">Reason 2 for decreasing fund</option>
                      <option value="Reason 2 for decreasing fund">Reason 3 for decreasing fund</option>
                      <option value="Reason 2 for decreasing fund">Reason 4 for decreasing fund</option>
                    </select>
                  )}
                </div>
                <div className="d-flex justify-content-center mt-3">
                  {userFunds?.reason ? (
                    <button className="purpleButton" onClick={updateWalletFunc}>
                      Submit
                    </button>
                  ) : (
                    <button className="purpleButton" style={{ opacity: "0.5" }}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {userFunds?.show && <div className="modal-backdrop fade show"></div>}
      {/* User Funds end*/}
    </div>
  );
}

export default UserList;
