import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNav from "../../../components/TopNav";
import TableNav from "../../../components/TableNav";
import { getAssignedBookingRecordServ } from "../../../services/bookingDashboard.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { useGlobalState } from "../../../GlobalProvider";
import NoRecordFound from "../../../components/NoRecordFound"
import Ably from "ably";
function SharingAssignedBooking() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Sharing",
      path: "/sharing-group-booking",
    },
    {
      name: "Personal",
      path: "/personal-new-booking",
    },
    {
      name: "Airport",
      path: "/airport-comming-soon",
    },
    {
      name: "Drive Test",
      path: "/drive-test-new-booking",
    },
    {
      name: "Intercity",
      path: "/intercity-comming-soon",
    },
    {
      name: "Driver's Availability",
      path: "/availability-new-booking",
    },
    {
      name: "Driver's Route",
      path: "/route-new-booking",
    },
    {
      name: "Out Of Area",
      path: "/out-of-area",
    },
  ];
  const tableNav = [
    
    {
      name: "Group",
      path: "/sharing-group-booking",
    },
    {
      name: "Route",
      path: "/sharing-route-booking",
    },
    {
      name: "Assigned",
      path: "/sharing-assigned-booking",
    },

    {
      name: "Accepted",
      path: "/sharing-accepted-booking",
    },
    {
      name: "Manual",
      path: "/sharing-manual-booking",
    },
    {
      name: "Missed",
      path: "/sharing-missed-booking",
    },
    {
      name: "Enroute",
      path: "/sharing-enroute-booking",
    },
    {
      name: "Completed",
      path: "/sharing-completed-booking",
    },
    {
      name: "Cancelled",
      path: "/sharing-cancelled-booking",
    },
  ];
  const [list, setList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const handleGetListFunc = async () => {
    if (list?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await getAssignedBookingRecordServ();
      if (response?.data?.statusCode == "200") {
        setList(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
      // Initialize Ably client with the API key
      const ably = new Ably.Realtime("cgbtZA.AQetNw:hE5TCgJHH9F4gWbFqv6pD5ScBM-A_RnW0RQG7xwQg-Y");
      const channel = ably.channels.get("booking-assigned");
    
      // Fetch user list initially
      handleGetListFunc();
    
      // Subscribe to 'group-updated' event
      channel.subscribe("booking-assigned", (message) => {
        console.log("Received 'group-updated' real-time update:", message.data);
        // Re-fetch user list when an update is received
        handleGetListFunc();
      });
    
      
    
      // Cleanup on component unmount
      return () => {
        channel.unsubscribe();
        ably.close();
      };
    }, []);
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Booking Dashboard" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section
        className="section_layout"
        style={{ minWidth: "2350px", marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}
      >
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#000"
          divideRowClass="col-xl-6 col-lg-6 col-md-12 col-12"
          selectedItem="Sharing"
          sectedNavBg="#139F01"
          selectedNavColor="#fff"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Assigned" sectedItemBg="#363435" selectedNavColor="#fff" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#363435" }}>
            <div style={{ margin: "20px 10px" }}>
              <table className="table bookingGroupTable">
                <thead>
                  <tr style={{ background: "#139F01", color: "#fff" }}>
                    <th scope="col" style={{ borderRadius: "24px 0px 0px 24px" }}>
                      <div className="d-flex justify-content-center ms-2">
                        <span className="mx-2">Sr. No</span>
                      </div>
                    </th>
                    <th scope="col"><span className="mx-2">Group ID</span> </th>
                    <th scope="col"><span className="mx-2">Booking ID</span> </th>
                    <th scope="col">Pick Address</th>
                    <th scope="col">Drop Address</th>
                    <th scope="col">Username</th>

                    <th scope="col">Booking Date</th>
                    <th scope="col">Booking Time</th>

                    <th scope="col">Time Choice</th>
                    <th scope="col">Booking Amount</th>
                    <th scope="col">Surge Amount</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Admin Fee</th>
                    <th scope="col">Driver Earn</th>
                    <th scope="col">Booking Placed</th>
                    
                    

                    <th scope="col" style={{ borderRadius: "0px 24px 24px 0px" }}>
                     Assigned Time
                    </th>
                  </tr>
                </thead>
                <div className="pt-3 pb-2 "></div>
                {showSkelton
                  ? [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <Skeleton width={50} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                         
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>

                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                        </tr>
                      );
                    })
                  : list?.map((value, index) => {
                      return (
                        <>
                          <tbody className="bg-light ">
                            {value?.map((v, i) => {
                              return (
                                <tr className=" ">
                                  <td
                                    scope="row"
                                    style={{
                                      borderTopLeftRadius: i == 0 ? "24px" : "0px",
                                      borderBottomLeftRadius: i + 1 == value?.length ? "24px" : "0px",
                                    }}
                                  >
                                    {i == 0 && index + 1}
                                  </td>

                                  <td>{i==0 && v?.group_id}</td>
                                  <td>{v?.id}</td>
                                  <td className="" style={{ padding: "0px" }}>
                                    <div className="d-flex justify-content-center">
                                      <div className="d-flex justify-content-between locationBoxButton" style={{background:"#363435"}}>
                                        <div>
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/3179/3179068.png"
                                          className=""
                                          style={{ height: "18px", top: "5px", position: "relative" }}
                                        />
                                        <div className="countDiv">{i+1}</div>
                                        </div>
                                        
                                        <span className="ms-2">{v?.source}</span>
                                      </div>{" "}
                                    </div>
                                  </td>
                                  <td className="" style={{ padding: "0px" }}>
                                    <div className="d-flex justify-content-center">
                                      <div className="d-flex justify-content-between locationBoxButton" style={{background:"#363435"}}>
                                        <div>
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/1483/1483285.png"
                                          className=""
                                          style={{ height: "18px", top: "5px", position: "relative" }}
                                        />
                                          <div className="countDivSmall">{i+1}</div>
                                        </div>
                                       
                                        <span className="ms-2">{v?.destination}</span>
                                      </div>{" "}
                                    </div>
                                  </td>

                                  <td>{v?.user_details?.first_name}</td>
                          <td style={{ color: "#ed2236" }}>{moment(v?.booking_date).format("DD/MM/YYYY")}</td>
                          <td style={{ color: " #139f01" }}>{v?.booking_time}</td>
                          <td>{v?.time_choice=="pickupat"? "Pickup" :" Dropoff"}</td>
                          <td>
                            <div style={{ width: "80px" }}>${v?.booking_amount}</div>{" "}
                          </td>
                          <td>
                            <div style={{ width: "80px" }}>${v?.extra_charge}</div>{" "}
                          </td>
                          <td>
                            <div style={{ width: "80px" }}>${v?.total_trip_cost}</div>{" "}
                          </td>
                          <td>
                            <div style={{ width: "80px" }}>${v?.admin_commission}</div>{" "}
                          </td>
                          <td>
                            <div style={{ width: "80px" }}>${v?.driver_earning}</div>{" "}
                          </td>
                          <td>
                          <div>{moment(v?.created_at).format("DD/MM/YYYY")}</div>
                          <div>{moment(v?.created_at).format("hh:mm A")}</div>
                          </td>
                          <td
                            style={{
                              borderTopRightRadius: i == 0 ? "24px" : "0px",
                              borderBottomRightRadius: i + 1 == value?.length ? "24px" : "0px",
                            }}
                          >
                            <div>{moment(v?.assign_time).format("DD/MM/YYYY")}</div>
                            <div>{moment(v?.assign_time).format("hh:mm A")}</div>
                          </td>
                        </tr>
                              );
                            })}
                          </tbody>
                          
                          <div className="mb-4"></div>
                        </>
                      );
                    })}
                    
              </table>
              {list?.length == 0 && !showSkelton && (
                <NoRecordFound theme="light"/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default SharingAssignedBooking;
