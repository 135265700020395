import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getUserInteractETransferServ, updateUserInteracStatusServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useGlobalState } from "../../GlobalProvider";
import NoRecordFound from "../../components/NoRecordFound"
function UserInteracDeposite() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },

    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Interac E-Transfer",
      path: "/user-interac-deposite",
    },
    {
      name: "Quick Deposit",
      path: "/user-quick-deposite",
    },
    {
      name: "G.T.Charges",
      path: "/pricing-gt-charges",
    },
    {
      name: "Email",
      path: "/integrated-email",
    },
  ];
  const [data, setData] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [staticsData, setStaticsData] = useState({
    total: "",
    monthly: "",
    today: "",
  });
  const getUserInteractETransferFund = async () => {
    if (data?.length == 0) {
      setShowSkelton(true);
    }
    setShowSkelton(true);
    try {
      let response = await getUserInteractETransferServ();
      if (response?.data?.statusCode == "200") {
        setStaticsData({
          total: parseInt(response?.data?.total_reject) + parseInt(response?.data?.total_accept),
          monthly: response?.data?.monthly_total,
          today: response?.data?.today_total,
        });
        console.log(response?.data);
        setData(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    getUserInteractETransferFund();
  }, []);
  const [rejectForm, setRejectForm] = useState({
    id: "",
    status: "-1",
    comment: "",
  });
  const updateStatus = async (id, status) => {
    if (status == "-1") {
      setRejectForm({
        id: id,
        status: "-1",
        comment: "",
      });
      return;
    }
    // Handle other statuses
    try {
      let response = await updateUserInteracStatusServ({ id, status });
      if (response?.data?.statusCode == "400") {
        toast.error(response?.data?.message);
      } else if (response?.data?.statusCode == "409") {
        toast.error(response?.data?.message);
      } else if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        getUserInteractETransferFund();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const handleRejectStatus = async (id, status) => {
    
    try {
      let response = await updateUserInteracStatusServ(rejectForm);
      if (response?.data?.statusCode == "400") {
        toast.error(response?.data?.message);
        
      } else if (response?.data?.statusCode == "409") {
        toast.error(response?.data?.message);
      } else if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        getUserInteractETransferFund();
        setRejectForm({
          id:"",
          comment:"",
          status:"-1"
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const [documentDetails, setDocumentDetails] = useState({
    show: false,
    image: "",
    first_name: "",
  });
  const downloadImage = async (url, filename) => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the Object URL
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Image download failed:", error);
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
          divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="User Deposit"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          navBg="#FD8917"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Interac E-Transfer" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50exceptTopLeft" style={{ background: "#f2fbff" }}>
            <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-4  m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Total Interac E-Transfer </p>
                  <p className="mb-0">${staticsData?.total}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Monthly Interac E-Transfer </p>
                  <p className="mb-0">${staticsData?.monthly}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Today's Interac E-Transfer </p>
                  <p className="mb-0">${staticsData?.today}</p>
                </div>
              </div>
            </div>
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Transaction Proof</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? Array.from({ length: 5 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={20} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={40} height={20} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                        </tr>
                      ))
                    : data?.data?.map((v, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{v?.user?.first_name}</td>
                          <td>{v?.user?.last_name}</td>
                          <td>{moment(v.created_date).format("DD/MM/YYYY")}</td>
                          <td>{v?.created_time}</td>
                          <td>${v?.amount}</td>
                          <td>
                            {v?.status === "1" ? (
                              <span style={{ color: "#139F01" }}>Accept</span>
                            ) : v?.status === "0" ? (
                              <span style={{ color: "#FD8917" }}>Pending</span>
                            ) : (
                              <span style={{ color: "#FC3D3E" }}>Reject</span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center iconDiv">
                              <img
                                onClick={() =>
                                  setDocumentDetails({
                                    img: v?.image,
                                    show: true,
                                    first_name: v?.user?.first_name,
                                  })
                                }
                                src="https://cdn-icons-png.flaticon.com/128/159/159604.png"
                                alt="proof"
                              />
                              <img
                                onClick={() =>
                                  downloadImage(v?.image, `${v?.user?.first_name}'s transaction_proof.png`)
                                }
                                src="https://cdn-icons-png.flaticon.com/128/724/724933.png"
                                alt="download proof"
                              />
                            </div>
                          </td>
                          <td className="d-flex justify-content-center align-items-center">
                            {v?.status == "1" ? (
                              <button
                                className="btn btn-sm btn-success"
                                style={{ borderRadius: "6px", background: "#139F01", border: "none", opacity: "0.5" }}
                                onClick={() => toast.error("Already accepted")}
                              >
                                Accepted
                              </button>
                            ) : (
                              <select value={v?.status} onChange={(e) => updateStatus(v?.id, e.target.value)}>
                                <option value="1">Accept</option>
                                <option value="-1">Reject</option>
                                <option value="0">Pending</option>
                              </select>
                            )}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              {data?.data?.length == 0 && !showSkelton && (
               <NoRecordFound/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

      {/* view transaction socument popup start */}
      {/* Modal */}
      {documentDetails.show && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Transaction Proof of "{documentDetails?.first_name}"</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() =>
                    setDocumentDetails({
                      show: false,
                      img: "",
                    })
                  }
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <img src={documentDetails?.img} className="img-fluid" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    setDocumentDetails({
                      show: false,
                      img: "",
                    })
                  }
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
                  Save changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* view transaction socument popup end*/}

      {rejectForm?.id && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp"
          tabIndex="-1"
        >
          <div className="modal-dialog" style={{ width: "370px" }}>
            <div className="modal-content fundsPopUpDiv p-4">
              <div className="d-flex justify-content-end p-2">
                <i
                  className="fa fa-close text-secondary"
                  onClick={() => {
                    setRejectForm({
                      id:"",
                      comment:"",
                      status:"-1"
                    });
                  }}
                ></i>
              </div>
              <h6 className="mb-4">User Interac</h6>
              <div className="modal-body p-0">
                
                <p className="">Update reason for rejection?</p>
                <div className="d-flex justify-content-center">
                 <select className="form-control" onChange={(e)=>setRejectForm({...rejectForm, comment:e.target.value})}>
                  <option value="">Select</option>
                  <option value="Incorrect Transfer Amount">Incorrect Transfer Amount</option>
                  <option value="Amount Not Received">Amount Not Received</option>
                  <option value="Screenshort is not clear">Screenshort is not clear</option>
                 </select>
                </div>
                
                <div>
                 
                </div>
                <div className="d-flex justify-content-center mt-3">
                  {rejectForm?.comment ? (
                    <button className="purpleButton" onClick={handleRejectStatus}>
                      Submit
                    </button>
                  ) : (
                    <button className="purpleButton" style={{ opacity: "0.5" }}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {rejectForm?.id && <div className="modal-backdrop fade show"></div>}
      {/* User Funds end*/}
    </div>
  );
}

export default UserInteracDeposite;
