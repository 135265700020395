import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGlobalState } from "../../GlobalProvider";
function UserChatBox() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Chat Support",
      path: "/user-chat-support",
    },
    {
      name: "Driver Chat Support",
      path: "/driver-chat-support",
    },
  ];
  const [showSkelton, setShowSkelton] = useState(false);
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Chat Support" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#000000"
          divideRowClass="col-xl-8 col-lg-8 col-md-12 col-12"
          selectedItem="User Chat Support"
          sectedNavBg="#B8336A"
          selectedNavColor="#fff"
        />
        {/* top nav ended  */}

        {/* table List started */}
        <div className="tableMain">
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#EBEBEB" }}>
            <div style={{ margin: "20px 10px" }}>
              <div className="chatBoxTopNav">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="d-flex justify-content-between align-items-center labelBox">
                      <p className="mb-0">First Name</p>
                      <h6 className="mb-0">Sandeep</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="d-flex justify-content-between align-items-center labelBox">
                      <p className="mb-0">Last Name</p>
                      <h6 className="mb-0">Singh</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="d-flex justify-content-between align-items-center labelBox">
                      <p className="mb-0">Case Id</p>
                      <h6 className="mb-0">87654321</h6>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="d-flex justify-content-between align-items-center labelBox">
                      <p className="mb-0">Booking ID</p>
                      <h6 className="mb-0">123456</h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex mt-4 align-items-center labelBox">
                      <p className="mb-0 me-5">Subject</p>
                      <h6 className="mb-0">Need more information regarding the sharing ride feature</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chatBoxDiv">
                <div className="d-flex justify-content-center">
                  <div className="chatActionBtnGroup d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center leftArrorDiv">

                    <img src="https://cdn-icons-png.flaticon.com/128/2985/2985161.png" />
                    </div>
                    <button>Sandeep</button>
                    <button>Aman</button>
                    <button>Gurmeet</button>
                  </div>
                </div>
                {[1, 2]?.map((v, i) => {
                  return (
                    <div className="chatContainer my-4">
                      <label>{i % 2 != 0 ? "Sandeep Singh" : "Aman"}</label>
                      <div
                        className="d-flex justify-content-between align-items-center chatDiv"
                        style={{ background: i % 2 != 0 ? " #b8336a" : " #024596" }}
                      >
                        <p className="mb-0">{i % 2 != 0 ? "Hello" : " Hii"}</p>
                        <h6 className="mb-0">12:02 pm</h6>
                      </div>
                    </div>
                  );
                })}
                <div className="d-flex justify-content-end ">
                  <div className="chatContainer  my-4" style={{ width: "50%" }}>
                    <label>Team Mie Ride</label>
                    <div
                      className="d-flex justify-content-between align-items-center chatDiv w-100"
                      style={{ background: "#000000" }}
                    >
                      <p className="mb-0">Hello</p>
                      <h6 className="mb-0">12:02 pm</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="addMessageBox d-flex justify-content-between align-items-center">
                  <img src="https://cdn-icons-png.flaticon.com/128/992/992651.png" />
                  <input placeholder="Add Message" />
                  <img src="https://cdn-icons-png.flaticon.com/128/6489/6489396.png" />
                  <img src="https://cdn-icons-png.flaticon.com/128/7175/7175253.png" />
                  <select>
                    <option>Select User</option>
                    <option>Aman</option>
                    <option>Sandeep</option>
                  </select>
                  <button>Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default UserChatBox;
