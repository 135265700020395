import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getDriverByIdServ, updateDriverServ } from "../../services/driver.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
function DriverAccount() {
  const { setGlobalState, globalState } = useGlobalState();
  const params = useParams()
  const tableNav = [
    {
      name: "Profile",
      path: `/driver-profile/${params?.id}`,
    },
    {
      name: "Document",
      path: `/driver-document/${params?.id}`,
    },
    {
      name: "Account",
      path: `/driver-account/${params?.id}`,
    },
    {
      name: "Rating",
      path: `/driver-rating/${params?.id}`,
    },
    {
      name: "Transaction History",
      path: `/driver-transaction-history/${params?.id}`,
    },
  ];
  const [data, setData] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [driverDetails, setDriverDetails] = useState(null);
  const getUserDetailsFunc = async () => {
    setShowSkelton(true);
    try {
      let response = await getDriverByIdServ(params.id);
      if (response?.data?.statusCode == "200") {
        setDriverDetails(response.data?.data?.driverDetails);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    getUserDetailsFunc();
  }, []);
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Driver" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}

        {/* top nav ended  */}
        {/* table List started */}
        <div className="">
          <TableNav tableNav={tableNav} selectedItem="Account" sectedItemBg="#FDEEE7" />
          <div className="tableBody py-2 px-4 borderRadius50All driverDetailsLabelInput" style={{ background: "#FDEEE7" }}>
            <div className=" p-5 my-4" style={{borderRadius:"20px", background:"#fff"}}>
            <div className="row d-flex justify-content-between">
                <div className="col-8 ">
                <div className="d-flex align-items-center mb-3">
                <input
                    type="checkbox"
                    style={{
                      height: "30px",
                      width: "30px",
                      border: "none",
                      borderRadius: "15px",
                      filter: "drop-shadow(0 0 8px rgba(0,0,0,0.06))",
                    }}
                    checked={driverDetails?.driver_account_details?.type === "0"}
                    />
                    <label style={{fontSize:"20px"}} className="ms-3">Interac E-Transfer</label>
                </div>
                  <label className=" col-form-label">Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={driverDetails?.driver_account_details?.email}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, first_name: e.target.value });
                    // }}
                    // readOnly={!isEditable}
                    style={{background:"#FDEEE7"}}
                  />
                  <div className="d-flex align-items-center my-5">
                <input
                    type="checkbox"
                    style={{
                      height: "30px",
                      width: "30px",
                      border: "none",
                      borderRadius: "15px",
                      filter: "drop-shadow(0 0 8px rgba(0,0,0,0.06))",
                      
                    }}
                    checked={driverDetails?.driver_account_details?.type === "1"}
                    />
                    <label style={{fontSize:"20px"}} className="ms-3">Direct Deposit Details</label>
                </div>
                <label className=" col-form-label">Customer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={driverDetails?.driver_account_details?.bank_name}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, first_name: e.target.value });
                    // }}
                    // readOnly={!isEditable}
                    style={{background:"#FDEEE7"}}
                  />
                  <label className=" col-form-label mt-1">Transit Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={driverDetails?.driver_account_details?.transit_no}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, first_name: e.target.value });
                    // }}
                    // readOnly={!isEditable}
                    style={{background:"#FDEEE7"}}
                  />
                  <label className=" col-form-label mt-1">Institution Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={driverDetails?.driver_account_details?.institution_no}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, first_name: e.target.value });
                    // }}
                    // readOnly={!isEditable}
                    style={{background:"#FDEEE7"}}
                  />
                  <label className=" col-form-label mt-1">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={driverDetails?.driver_account_details?.account}
                    // onChange={(e) => {
                    //   setFormData({ ...formData, first_name: e.target.value });
                    // }}
                    // readOnly={!isEditable}
                    style={{background:"#FDEEE7"}}
                  />
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

      
    </div>
  );
}

export default DriverAccount;
