import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getUserQuickServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { useGlobalState } from "../../GlobalProvider";
import NoRecordFound from "../../components/NoRecordFound"
function UserQuickDeposite() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },
    
    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Interac E-Transfer",
      path: "/user-interac-deposite",
    },
    {
      name: "Quick Deposit",
      path: "/user-quick-deposite",
    },
    {
      name: "G.T.Charges",
      path: "/pricing-gt-charges",
    },
    {
      name: "Email",
      path: "/integrated-email",
    },
  ];
  const [data, setData] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [staticsData, setStaticsData] = useState({
    total: "",
    monthly: "",
    today: "",
  });
  const getUserInteractETransferFund = async () => {
    setShowSkelton(true);
    try {
      let response = await getUserQuickServ();
      if (response?.data?.statusCode == "200") {
        setStaticsData({
          total: parseInt(response?.data?.total) ,
          monthly: response?.data?.monthly_total,
          today: response?.data?.today_total,
        });
        console.log(response?.data);
        setData(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    getUserInteractETransferFund();
  }, []);
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}
      
      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft : globalState?.isFillSidebarWidth100 ? " 260px": " 80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
          divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="User Deposit"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          navBg="#FD8917"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Quick Deposit" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#f2fbff" }}>
            <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-4  m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Total Quick Deposit</p>
                  <p className="mb-0">${staticsData?.total}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Monthly Quick Deposit</p>
                  <p className="mb-0">${staticsData?.monthly}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Today's Quick Deposit</p>
                  <p className="mb-0">${staticsData?.today}</p>
                </div>
              </div>
            </div>
            <div style={{ margin: "0px 10px"  }}>
              <table className="table">
                <thead >
                  <tr style={{ background: "#DCE4E7", }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Source</th>
                    <th scope="col">Transaction Proof</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Status
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <tr key={index}>
                        <td><Skeleton width={50} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                        <td><Skeleton width={100} /></td>
                      </tr>
                    ))
                  ) : (
                    data?.data?.map((v, i) => (
                      <tr key={v.transaction_id}>
                        <td scope="row">{i + 1}</td>
                        <td>{v?.user?.first_name}</td>
                        <td>{v?.user?.last_name}</td>
                        <td>{moment(v.created_date).format("DD/MM/YYYY")}</td>
                          <td>{v?.created_time}</td>
                        <td>${v?.amount}</td>
                        <td>{v?.source}</td>
                        <td>{v?.transaction_id}</td>
                        <td>
                          {v?.status === "1" ? (
                            <span style={{ color: "#139F01" }}>Accept</span>
                          ) : v?.status === "0" ? (
                            <span style={{ color: "#FD8917" }}>Pending</span>
                          ) : (
                            <span style={{ color: "#FC3D3E" }}>Reject</span>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              {data?.data?.length == 0 && !showSkelton && (
               <NoRecordFound/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default UserQuickDeposite;
