import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getUserInteractETransferServ, updateUserInteracStatusServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useGlobalState } from "../../GlobalProvider";
function IntegratedEmail() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },

    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Interac E-Transfer",
      path: "/user-interac-deposite",
    },
    {
      name: "Quick Deposit",
      path: "/user-quick-deposite",
    },
    {
      name: "G.T.Charges",
      path: "/pricing-gt-charges",
    },
    {
      name: "Email",
      path: "/integrated-email",
    },
  ];
  
  
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
          divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="User Deposit"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          navBg="#FD8917"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Email" sectedItemBg="#363535" selectedNavColor="#fff" />
          <div className="tableBody py-2 px-4 borderRadius50All " style={{ background: "#363535" }}>
            <div className="row my-3">
              <div className="col-3">
                <div className="bg-light h-100 p-3" style={{borderRadius:"20px"}}>
                  <button className="btn btn-primary  w-100 ">Compose</button>
                  <div className="my-4">
                    <h6 className="text-secondary">MAILBOX</h6>
                    <hr className="mt-0"/>
                    <div className="my-2">
                      <div className="d-flex justify-content-between align-items-center border p-2 rounded shadow-sm">
                      <p className="mb-0"><i className="fa fa-inbox "></i> Inbox</p>
                      <p style={{fontSize:"12px"}} className="text-primary mb-0">12</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <div className="bg-light shadow " style={{borderRadius:"24px", padding:"12px 16px"}}>
                  <i className="fa fa-search text-secondary me-2"></i>
                  <input placeholder="Search Email" style={{border:"none", outline:"none"}}/>
                </div>
                <div className="mt-3">
                  {[1, 2, 3, 4, 5]?.map((v, i)=>{
                    return(
                      <div className="bg-light p-3  mb-3" style={{borderRadius:"12px"}}>
                        <div className="d-flex align-items-center">
                          <img src="https://phoenix-react-alt.prium.me/static/media/57.971121ad428013d5bb70.webp" style={{height:"40px", width:"40px", borderRadius:"50%"}}/>
                          <p className="mb-0 ms-2">Harjeet Singh</p>
                        </div>
                        <h6 className="mt-2">This is subject</h6>
                        <p className="mb-0">this is para content sdfbdj gjhgbjdfg dffigbdfjg df</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default IntegratedEmail;
