import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getDriverByIdServ, updateDriverServ, deleteDriverServ } from "../../services/driver.services";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function AddUser() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const vehicleColorList = [
    "White",
    "Black",
    "Gray",
    "Silver",
    "Beige",
    "Red",
    "Blue",
    "Yellow",
    "Green",
    "Orange",
    "Purple",
    "Metallic Silver",
    "Metallic Gray",
    "Metallic Blue",
    "Metallic Red",
    "Metallic Gold",
    "Pearl White",
    "Matte Black",
    "Glossy Red",
    "Chrome",
    "Two-Tone",
  ];
  const yearList = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
  ];
  const brandToModels = [
    {
      brand: "Toyota",
      modal: ["Corolla", "Camry", "RAV4", "Highlander", "Tacoma", "Tundra", "Prius"],
    },
    {
      brand: "Honda",
      modal: ["Civic", "Accord", "CR-V", "HR-V", "Pilot", "Ridgeline"],
    },
    {
      brand: "Ford",
      modal: ["F-150", "Escape", "Edge", "Explorer", "Mustang", "Maverick"],
    },
    {
      brand: "Chevrolet",
      modal: ["Silverado", "Equinox", "Traverse", "Malibu", "Tahoe", "Bolt EV"],
    },
    {
      brand: "Nissan",
      modal: ["Sentra", "Altima", "Rogue", "Murano", "Pathfinder", "Frontier"],
    },
    {
      brand: "Hyundai",
      modal: ["Elantra", "Sonata", "Tucson", "Santa Fe", "Kona", "Palisade"],
    },
    {
      brand: "Kia",
      modal: ["Forte", "Optima", "Sportage", "Sorento", "Telluride", "Seltos"],
    },
    {
      brand: "Volkswagen",
      modal: ["Jetta", "Golf", "Tiguan", "Passat", "Atlas", "ID.4"],
    },
    {
      brand: "Subaru",
      modal: ["Impreza", "Legacy", "Forester", "Outback", "Crosstrek", "Ascent"],
    },
    {
      brand: "Mazda",
      modal: ["Mazda3", "Mazda6", "CX-30", "CX-5", "CX-9", "MX-5 Miata"],
    },
    {
      brand: "BMW",
      modal: ["3 Series", "5 Series", "X3", "X5", "X7", "i4"],
    },
    {
      brand: "Mercedes-Benz",
      modal: ["C-Class", "E-Class", "GLC", "GLE", "GLS", "EQB"],
    },
    {
      brand: "Audi",
      modal: ["A3", "A4", "A6", "Q3", "Q5", "Q7", "e-tron"],
    },
    {
      brand: "Tesla",
      modal: ["Model 3", "Model Y", "Model S", "Model X"],
    },
    {
      brand: "GMC",
      modal: ["Sierra", "Terrain", "Acadia", "Yukon", "Canyon"],
    },
    {
      brand: "Jeep",
      modal: ["Wrangler", "Cherokee", "Grand Cherokee", "Compass", "Gladiator"],
    },
    {
      brand: "Dodge",
      modal: ["Challenger", "Charger", "Durango", "Grand Caravan"],
    },
    {
      brand: "Ram",
      modal: ["1500", "2500", "ProMaster"],
    },
    {
      brand: "Lexus",
      modal: ["RX", "NX", "ES", "UX", "GX", "LS"],
    },
    {
      brand: "Acura",
      modal: ["ILX", "TLX", "RDX", "MDX", "Integra"],
    },
    {
      brand: "Volvo",
      modal: ["XC40", "XC60", "XC90", "S60", "V60", "C40"],
    },
    {
      brand: "Mitsubishi",
      modal: ["Mirage", "Lancer", "Outlander", "Eclipse Cross"],
    },
    {
      brand: "Land Rover",
      modal: ["Range Rover", "Discovery", "Defender", "Evoque", "Velar"],
    },
    {
      brand: "Porsche",
      modal: ["911", "Macan", "Cayenne", "Taycan", "Panamera"],
    },
    {
      brand: "Mini",
      modal: ["Cooper", "Countryman", "Clubman"],
    },
    {
      brand: "Cadillac",
      modal: ["CT4", "CT5", "XT4", "XT5", "Escalade", "Lyriq"],
    },
    {
      brand: "Infiniti",
      modal: ["Q50", "Q60", "QX50", "QX60", "QX80"],
    },
    {
      brand: "Jaguar",
      modal: ["XE", "XF", "F-Pace", "E-Pace", "I-Pace", "F-Type"],
    },
    {
      brand: "Alfa Romeo",
      modal: ["Giulia", "Stelvio", "Tonale"],
    },
    {
      brand: "Fiat",
      modal: ["500X", "500L", "Panda"],
    },
  ];

  const vehicleSizeList = ["4 Seater", "6 Seater"];
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    password: "",
    vehicle_brand: "",
    vehicle_date: "",
    vehicle_colour: "",
    vehicle_size: "",
  });
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="User" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section
        className="section_layout"
        style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px", paddingTop: "30px" }}
      >
        {/* top nav started  */}

        {/* top nav ended  */}
        {/* table List started */}
        <div className="">
          <div
            className="tableBody py-2 px-4 driverDetailsLabelInput borderRadius50All"
            style={{ background: "#FDEEE7" }}
          >
            <div className=" p-5  my-4" style={{ borderRadius: "20px", background: "#fff" }}>
              <div className="d-flex align-items-center justify-content-between">
                <h5>Personal Details</h5>
                <div
                  onClick={() => navigate("/user-list")}
                  className="addUserBtn d-flex justify-content-between align-items-center "
                  style={{ background: "#7650E0", cursor: "pointer" }}
                >
                  <p className="mb-0 me-2">User List</p>
                  <img src="https://cdn-icons-png.flaticon.com/128/1077/1077114.png" />
                </div>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-8 ">
                  <label className=" col-form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData?.first_name}
                    onChange={(e) => {
                      setFormData({ ...formData, first_name: e.target.value });
                    }}
                  />
                  <label className=" col-form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData?.last_name}
                    onChange={(e) => {
                      setFormData({ ...formData, last_name: e.target.value });
                    }}
                  />
                  <label className=" col-form-label">Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData?.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                  <label className=" col-form-label">Phone Number</label>
                  <div className="d-flex align-items-center  " style={{ borderRadius: "9px" }}>
                    <PhoneInput
                      country={"in"} // Default country
                      // onChange={handlePhoneChange}
                      inputStyle={{
                        width: "100px",
                        height: "40px",
                        fontSize: "16px",
                        background: "#fff",
                        border: "none",
                      }}
                      buttonStyle={{
                        background: "#fff",
                        border: "none",
                        padding: "0px",
                      }}
                    />
                    <input
                      placeholder=""
                      className="form-control"
                      value={formData?.contact}
                      // onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                      style={{ marginBottom: "0px", padding: "6px", lineHeight: "0px", outline: "none" }}
                    />
                  </div>

                  <label className=" col-form-label">Password</label>
                  <input type="password" className="form-control" value={formData?.password} />

                  <label className=" col-form-label">Gender</label>
                  <select className="form-control">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-3 ">
                  <div className="d-flex justify-content-center">
                    {formData?.image ? (
                      <img
                        src={`https://api.faizah.in/storage/${formData?.image}`}
                        style={{ height: "150px", width: "150px", borderRadius: "50%" }}
                      />
                    ) : (
                      <div
                        className="cameraIcon d-flex justify-content-center align-items-center"
                        style={{ height: "150px", width: "150px" }}
                      >
                        <img
                          src={"https://cdn-icons-png.flaticon.com/128/711/711191.png"}
                          style={{ height: "80px", width: "80px" }}
                        />
                      </div>
                    )}
                  </div>
                  <label>Status</label>
                  <div className="text-center">
                    <select
                      value={formData?.status}
                      className="form-control mt-1"
                      onChange={(e) => {
                        setFormData({ ...formData, status: e.target.value });
                      }}
                    >
                      <option value="Approve">Active</option>
                      <option value="Disapprove">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-around mt-3">
                <div className="col-6">
                  <button className="btn btn-primary w-100" style={{ background: "#139F01" }}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default AddUser;
