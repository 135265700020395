import React from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";

function ChatCommingSoon() {
  const { setGlobalState, globalState } = useGlobalState();
  
  
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Reports"/>
      {/* sidebar ended */}
      
      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        
        
        <div className="vh80 d-flex justify-content-center align-items-center">
                <h1>Report Comming Soon</h1>
            </div>
          
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default ChatCommingSoon;
