import React, {useState, useEffect} from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
import { listSwitchAccountServ, updateSwitchAccountServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import NoRecordFound from "../../components/NoRecordFound"
function FundsSwitch() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposit",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },
    
    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const [list, setList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const getSwitchFundList = async () => {
    if (list?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await listSwitchAccountServ({});
      if (response?.data?.statusCode == "200") {
        
        setList(response?.data?.data);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
    setShowSkelton(false);
  };
  useEffect(() => {
    getSwitchFundList();
  }, []);

  const [popupData, setPopupData]=useState(null)
  const renderStatus = (status)=>{
    if(status=="0"){
      return(
        <span style={{color:"#745A37"}}>Pending</span>
      )
    }
    if(status=="1"){
      return(
        <span style={{color:"#139f01"}}>Approved</span>
      )
    }
    if(status=="-1"){
      return(
        <span style={{color:"red"}}>Rejected</span>
      )
    }
  }
  const handleUpdateStatus = async (formData)=>{
    try {
      let response = await updateSwitchAccountServ(formData);
      if(response?.data?.statusCode=="200"){
        toast.success(response?.data?.message);
        getSwitchFundList()
      }
      else{
        toast?.error(response?.data?.error)
      }
    } catch (error) {
      toast.error("Internal Server Error")
    }
  }
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management"/>
      {/* sidebar ended */}
      
      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
         divideRowClass="col-xl-9 col-lg-9 col-md-12 col-12"
          selectedItem="Switch"
          sectedNavBg="#043B64"
          selectedNavColor="#fff"
          navBg="#EAB56F"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          {/* <TableNav tableNav={tableNav} selectedItem="Quick Deposit" sectedItemBg="#f2fbff" /> */}
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#f2fbff" }}>
            
            <div style={{ margin: "20px 10px"  }}>
              <table className="table">
                <thead >
                  <tr style={{ background: "#DCE4E7", }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Switch From</th>
                    <th scope="col">Switch To</th>
                    <th scope="col">Date</th>
                    <th scope="col">Profile</th>
                    <th scope="col">Status</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody >
                  {showSkelton
                    ? [1, 2, 3, 4, 5, 6, 7]?.map((v, i) => {
                        return (
                          <tr>
                            <td>
                              <Skeleton width={40} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        );
                      })
                    :list?.map((v, i) => {
                    return (
                      <tr>
                        <td scope="row">{i+1}</td>
                        <td>{v?.driver?.first_name}</td>
                        <td>{v?.driver?.last_name}</td>
                        <td style={{color: v?.switch_from=="1"? "#9029f8": "#bf6d02"}}>{v?.switch_from=="0"? "Interac E-Transfer": "Direct Deposite"}</td>
                        <td style={{color: v?.switch_to=="1"? "#9029f8": "#bf6d02"}}>{v?.switch_to=="0"? "Interac E-Transfer": "Direct Deposite"}</td>
                       
                        <td>{moment(v.created_at).format("DD/MM/YYYY")}</td>
                        <td>
                        <div className="d-flex justify-content-center iconDiv" >
                          <img src="https://cdn-icons-png.flaticon.com/128/159/159604.png" onClick={()=>setPopupData(v)}/>
                          
                          </div>
                        </td>
                        <td>
                          {renderStatus(v?.status)}
 
                        </td>
                        <td className="d-flex justify-content-center align-items-center">
                          <select value={v?.status} className="shadow" onChange={(e)=>handleUpdateStatus({id:v?.id, status:e.target.value})}  style={{background:"#fff", color:v?.status==0 ?"#745A37":v?.status==1? "#139F01":"red"}}>
                            <option value="0">Pending</option>
                            <option value="1">Accept</option>
                            <option value="-1">Reject</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {list.length == 0 && !showSkelton && (
               <NoRecordFound/>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
      {/* User Funds Start */}
      {/* Modal */}
      {popupData && (
        <div
          className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp"
          tabIndex="-1"
        >
          <div className="modal-dialog" style={{width:"500px"  }}>
            <div className="modal-content  w-100 switchPopUpDiv">
              <div className="d-flex justify-content-end ">
                <i
                  className="fa fa-close text-secondary p-2"
                  onClick={()=>setPopupData(null)}
                ></i>
              </div>
              <h6 className="mb-4">Profile</h6>
              <div className="modal-body p-0" >
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Full Name</p>
                    <input value={popupData?.driver?.first_name +" "+popupData?.driver?.last_name}/>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Email Address</p>
                    <input value={popupData?.driver?.email}/>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Phone Number</p>
                    <input  value={popupData?.driver?.country_code +" "
                      + popupData?.driver?.contact}/>
                  </div>
                  </div>
                  <h6 className="my-4">Interac E-Transfer Details</h6>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Email Address</p>
                    
                    <input value={popupData?.driver_account_details?.type=="0" ? popupData?.driver_account_details?.email:  popupData?.email}/>
                  </div>
                  <h6 className="my-4">Direct Deposite Details</h6>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Customer Name</p>
                    <input value={popupData?.driver_account_details?.type=="0" ? popupData?.bank_name:  popupData?.driver_account_details?.bank_name}/>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Transit Number</p>
                    <input value={popupData?.driver_account_details?.type=="0" ? popupData?.transit_no:  popupData?.driver_account_details?.transit_no}/>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Institution Number</p>
                    <input value={popupData?.driver_account_details?.type=="0" ? popupData?.institution_no:  popupData?.driver_account_details?.institution_no}/>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="mb-0">Account Number</p>
                    <input value={popupData?.driver_account_details?.type=="0" ? popupData?.account:  popupData?.driver_account_details?.account}/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {popupData && <div className="modal-backdrop fade show"></div>}
      {/* User Funds end*/}
    </div>
  );
}

export default FundsSwitch;
