import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNav from "../../../components/TopNav";
import TableNav from "../../../components/TableNav";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGlobalState } from "../../../GlobalProvider";
import { useParams, useNavigate } from "react-router-dom";
import { getRouteByGroupIdServ, selectDriverManuallyServ } from "../../../services/bookingDashboard.services";
import { toast } from "react-toastify";
function SharingSelectAvalabilityDriverBooking() {
  const [formData, setFormData] = useState({
    booking_route_id: "",
    driver_ids: [],
    tip_amount: "",
  });
  const params = useParams();
  const navigate = useNavigate();
  const { setGlobalState, globalState } = useGlobalState();
  const [carSize, setCarSize] = useState(4);
  const navItems = [
    {
      name: "Route",
      path: "/sharing-select-route-driver/" + params?.id,
    },
    {
      name: "Availability",
      path: "/sharing-select-avilability-driver/" + params?.id,
    },
    {
      name: "Manually",
      path: "/sharing-select-manual-driver/" + params?.id,
    },
    {
      name: "All",
      path: "/sharing-select-driver/" + params?.id,
    },
  ];
  const [showSkeltonForDetails, setShowSkeltonForDetails] = useState(false);
  const [details, setDetails] = useState();
  const [allDriverIds, setAllDriverIds] = useState([]);
  const getBookingDetailsFunc = async () => {
    setShowSkeltonForDetails(true);
    try {
      let response = await getRouteByGroupIdServ({ group_id: params.id });
      if (response?.data?.statusCode == "200") {
        setDetails(response?.data?.data);
        setFormData({
          driver_ids: [],
          tip_amount: "",
          booking_route_id: response?.data?.data?.routeDetails?.id,
        });
        setAllDriverIds(details?.driverAvailabilities?.map((v) => v?.driver_details?.id) || []);
        setGlobalAreAllIdsSelected(false);
      }
    } catch (error) {}
    setShowSkeltonForDetails(false);
  };
  useEffect(() => {
    getBookingDetailsFunc();
  }, []);
  const [globalAreAllIdsSelected, setGlobalAreAllIdsSelected] = useState(false);

  const addAllDriverIds = () => {
    // Fetch all driver IDs
    const allDriverIds = details?.driverAvailabilities?.map((v) => v?.driver_details?.id) || [];

    // Determine if all IDs are currently selected
    const areAllIdsSelected =
      formData?.driver_ids?.length === allDriverIds.length &&
      allDriverIds.every((id) => formData?.driver_ids?.includes(id));

    // Toggle the selection
    setGlobalAreAllIdsSelected(!areAllIdsSelected);
    setFormData({
      ...formData,
      driver_ids: areAllIdsSelected ? [] : allDriverIds, // Clear or set all IDs
    });
  };

  const addDriverId = (id) => {
    setFormData((prevFormData) => {
      const driver_ids = prevFormData?.driver_ids || [];

      let updatedDriverIds;
      // Check if the ID is already included
      if (driver_ids.includes(id)) {
        // Remove the ID
        updatedDriverIds = driver_ids.filter((driverId) => driverId !== id);
      } else {
        // Add the ID
        updatedDriverIds = [...driver_ids, id];
      }

      // Determine if all driver IDs are now selected
      const allDriverIds = details?.driverAvailabilities?.map((v) => v?.driver_details?.id) || [];
      const areAllIdsSelected =
        updatedDriverIds.length === allDriverIds.length &&
        allDriverIds.every((driverId) => updatedDriverIds.includes(driverId));

      // Update the global state for all IDs selected
      setGlobalAreAllIdsSelected(areAllIdsSelected);

      return {
        ...prevFormData,
        driver_ids: updatedDriverIds,
      };
    });
  };
  const handleSubmitDriverSelectFunc = async () => {
    const selectedFormData = new FormData();
    selectedFormData?.append("booking_route_id", formData?.booking_route_id);
    selectedFormData?.append("tip_amount", formData?.tip_amount);
    formData?.driver_ids?.forEach((id) => {
      selectedFormData.append("driver_ids[]", id);
    });
    try {
      let response = await selectDriverManuallyServ(selectedFormData);
      if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        navigate("/sharing-manual-booking");
      } else if (response?.data?.statusCode == "400") {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Booking Dashboard" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft: globalState?.isFillSidebarWidth100 ? "260px" : "80px" }}>
        {/* table List started */}
        <div className="">
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#F3F3F3" }}>
            <div className="pb-1 pt-4 d-flex justify-content-end ">
              <div
                className="btn btn-warning btn-sm px-4"
                style={{ borderRadius: "15px" }}
                onClick={() => navigate("/sharing-manual-booking")}
              >
                Back <i className="fa fa-close ms-2"></i>
              </div>
            </div>
            <div className="chatBoxTopNav mt-4 mb-4">
              <div className="row">
                {showSkeltonForDetails
                  ? [1, 2, 3, 4]?.map((v, i) => {
                      return (
                        <div className="col-lg-3 col-md-6 mb-3">
                          <Skeleton height="60px" />
                        </div>
                      );
                    })
                  : details?.bookingDetails?.map((v, i) => {
                      return (
                        <div className="col-lg-3 col-md-6 mb-3">
                          <div className="d-flex justify-content-between align-items-center labelBox">
                            <div>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/3179/3179068.png"
                                className=""
                                style={{ height: "18px" }}
                              />
                              <div
                                className="countDiv d-flex justify-content-center align-items-center"
                                style={{
                                  color: "#fff",

                                  textAlign: "center",
                                  top: "-17px",
                                  position: "relative",
                                  background: "#139F01",
                                }}
                              >
                                <span>{i + 1}</span>
                              </div>
                            </div>
                            <h6
                              className="mb-0"
                              style={{
                                width: "90%",
                                marginTop: "-6px",

                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                color: "#000",
                              }}
                            >
                              {v?.pickup_point}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="row">
                {showSkeltonForDetails
                  ? [1, 2, 3, 4]?.map((v, i) => {
                      return (
                        <div className="col-lg-3 col-md-6 mb-3">
                          <Skeleton height="60px" />
                        </div>
                      );
                    })
                  : details?.bookingDetails?.map((v, i) => {
                      return (
                        <div className="col-lg-3 col-md-6 mb-3">
                          <div
                            className="d-flex justify-content-between align-items-center labelBox"
                            style={{ background: "#000" }}
                          >
                            <div>
                              <img
                                src="https://cdn-icons-png.flaticon.com/128/1483/1483285.png"
                                className=""
                                style={{ height: "22px", filter: "brightness(0) invert(1)" }}
                              />
                              <div
                                className="countDiv d-flex justify-content-center "
                                style={{
                                  color: "#fff",
                                  textAlign: "center",
                                  top: "-21px",
                                  left: "6px",
                                  position: "relative",
                                  background: "#D64E03",
                                }}
                              >
                                <span>{i + 1}</span>
                              </div>
                            </div>
                            <h6
                              className="mb-0"
                              style={{
                                width: "90%",
                                marginTop: "-6px",
                                color: "#fff",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {v?.dropoff_point}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="row my-4">
              <div className="col-7">
                <div
                  className="d-flex justify-content-between align-items-center p-4 shadow"
                  style={{ borderRadius: "20px", background: "white" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center  "
                    style={{
                      width: "47%",
                      borderRadius: "6px",
                      background: "#024596",
                      color: "white",
                      padding: "10px 20px",
                    }}
                  >
                    <p className="mb-0">Total Amount</p>
                    <h6 className="mb-0">
                      ${details?.routeDetails?.total_trip_amount ? details?.routeDetails?.total_trip_amount : " ..."}
                    </h6>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      width: "47%",
                      borderRadius: "6px",
                      background: "#139F02",
                      color: "white",
                      padding: "10px 20px",
                    }}
                  >
                    <p className="mb-0">First Pickup Time</p>
                    <h6 className="mb-0">
                      {details?.routeDetails?.first_pickup_time ? details?.routeDetails?.first_pickup_time : "..."}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div
                  className="d-flex w-100 align-items-center p-4 shadow"
                  style={{ borderRadius: "20px", background: "white" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center shadow-sm w-100"
                    style={{
                      borderRadius: "6px",
                      background: "#fff",
                      color: "#000",
                      padding: "10px 20px",
                      width: "80%",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <input
                        style={{ border: "none", outline: "none" }}
                        type="number"
                        placeholder="Extra Charges"
                        value={formData?.tip_amount}
                        onChange={(e) => setFormData({ ...formData, tip_amount: e.target.value })}
                      />
                      <h6 className="mb-0 me-3">$</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* top nav started  */}
            <TopNav
              navItems={navItems}
              navColor="#000"
              navBg="#fff"
              divideRowClass="col-xl-12 col-lg-12 col-md-12 col-12"
              selectedItem="Availability"
              sectedNavBg="#B8336A"
              selectedNavColor="#fff"
            />
            {/* top nav ended  */}
            <div className="row my-4 p-4">
              <div className="col-2 my-auto">
                <h5 className="mb-0">Select Car Type</h5>
              </div>
              <div className="col-3">
                <div className="d-flex justify-content-between align-items-center w-100 ">
                  {carSize == 4 ? (
                    <button
                      className="btn btn-warning shadow"
                      style={{
                        padding: "5px 8px",
                        background: "#fff",
                        border: "none",
                        width: "38px",
                        height: "38px",
                      }}
                    >
                      <i className="fa fa-check text-success"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning shadow"
                      style={{
                        padding: "5px 8px",
                        background: "#fff",
                        border: "none",
                        width: "38px",
                        height: "38px",
                      }}
                    ></button>
                  )}

                  <button className="btn btn-dark" style={{ background: "#000", width: "80%" }}>
                    4-Seater
                  </button>
                </div>
              </div>
              <div className="col-3" style={{ opacity: "0.5" }} onClick={() => alert("Coming soon")}>
                <div className="d-flex justify-content-between align-items-center w-100 ">
                  <button
                    className="btn btn-warning shadow"
                    style={{
                      padding: "5px 8px",
                      background: "#fff",
                      border: "none",
                      width: "38px",
                      height: "38px",
                    }}
                  ></button>
                  <button className="btn btn-dark" style={{ background: "#000", width: "80%" }}>
                    6-Seater
                  </button>
                </div>
              </div>

              <div className="col-4">
                <div className="d-flex justify-content-end align-items-center w-100 ">
                  {formData?.booking_route_id && formData?.driver_ids.length > 0 ? (
                    <button
                      className="btn btn-success"
                      onClick={handleSubmitDriverSelectFunc}
                      style={{ borderRadius: "18px", background: "#139F02", width: "120px", border: "none" }}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      style={{
                        background: "#139F02",
                        width: "120px",
                        border: "none",
                        borderRadius: "18px",
                        opacity: "0.5",
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <table className="table bookingTable">
                <thead>
                  <tr style={{ background: "#DDDDDD", color: "#000" }}>
                    <th scope="col" style={{ borderRadius: "12px 0px 0px 12px" }}>
                      Sr. No
                    </th>
                    <th scope="col">Name</th>
                    <th scope="col">Car Type</th>
                    <th scope="col"> Car Number</th>
                    <th scope="col" style={{ borderRadius: "0px 12px 12px 0px" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="btn btn-warning shadow"
                          onClick={addAllDriverIds}
                          style={{
                            padding: "2.5px 4px",
                            background: "#fff",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            color: "#139F02",
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {globalAreAllIdsSelected ? "✔" : " "}
                        </button>
                        <div className="ms-2">All</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkeltonForDetails
                    ? [1, 2, 3, 4, 5]?.map((v, i) => {
                        return (
                          <tr className=" ">
                            <td scope="row" style={{ borderTopLeftRadius: "24px", borderBottomLeftRadius: "24px" }}>
                              <Skeleton width={30} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td style={{ width: "300px" }}>
                              <Skeleton width={100} />
                            </td>

                            <td>
                              <Skeleton width={100} />
                            </td>

                            <td
                              style={{
                                borderTopRightRadius: "24px",
                                borderBottomRightRadius: "24px",
                                overflow: "hidden",
                              }}
                            >
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        );
                      })
                    : details?.driverAvailabilities?.map((v, i) => {
                        return (
                          <>
                            <tr className=" ">
                              <td scope="row" style={{ borderTopLeftRadius: "24px", borderBottomLeftRadius: "24px" }}>
                                {i + 1}
                              </td>
                              <td>
                                {v?.driver_details?.first_name} {v?.driver_details?.last_name}
                              </td>
                              <td style={{ width: "300px" }}>{v?.driver_details?.vehicle_size}</td>

                              <td>{v?.driver_details?.vehicle_no}</td>

                              <td
                                style={{
                                  borderTopRightRadius: "24px",
                                  borderBottomRightRadius: "24px",
                                  overflow: "hidden",
                                }}
                              >
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ borderRadius: "12px", width: "100%", height: "100%" }}
                                >
                                  <button
                                    className="btn btn-warning shadow"
                                    onClick={() => addDriverId(v?.driver_details?.id)}
                                    style={{
                                      padding: "5px 8px",
                                      background: "#fff",
                                      border: "none",
                                      width: "30px",
                                      height: "30px",
                                      color: "#139F02",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {formData?.driver_ids?.includes(v?.driver_details?.id) ? "✔" : " "}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                </tbody>
              </table>
              {details?.driverAvailabilities?.length == 0 && (
                <div
                  className="bg-light d-flex justify-content-center align-items-center w-100 "
                  style={{ borderRadius: "12px", height: "50vh" }}
                >
                  <div style={{ opacity: "0.8", fontFamily: "monospace" }}>
                    <img src="https://cdn-icons-png.flaticon.com/256/6840/6840178.png" />
                    <h2 className="text-center">
                      <b>No Record Found</b>
                    </h2>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default SharingSelectAvalabilityDriverBooking;
