import React, { useState , useEffect} from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import { addCommissionServ , getCommissionListServ, editCommissionServ} from "../../services/priceAndCity.services";
import { toast } from "react-toastify";
import { useGlobalState } from "../../GlobalProvider";
function PricingCommission() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Categories",
      path: "/pricing-categories",
    },
    {
      name: "Province",
      path: "/pricing-province",
    },
    {
      name: "Location",
      path: "/pricing-sharing-location",
    },
    {
      name: "Surges",
      path: "/pricing-sharing-surges",
    },
    {
      name: "Commission",
      path: "/pricing-commission",
    },
    
    {
      name: "Interac Id",
      path: "/pricing-iterac-id",
    },
    {
      name: "Payout Info",
      path: "/pricing-payout-info",
    },
    {
      name: "Cancel",
      path: "/pricing-cancel",
    },
  ];
  const [sharingFormData, setSharingFormData] = useState({
    category_id: "1",
    our_commission: "",
    driver_commission: "",
    is_editable:false
  });
  const handleAddCommission = async () => {
    try {
      let response = await addCommissionServ(sharingFormData);
      if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
      }
      if (response?.data?.statusCode == "409") {
        toast.error("A commission for this category already exists");
      }
    } catch (error) {
      toast.success("Internal Server Error");
    }
  };
  const [commissionList, setCommissionList]=useState();

  const handleGetCommissionListFunc = async ()=>{
    try {
      let response = await getCommissionListServ();
      if (response?.data?.statusCode == "200") {
        setCommissionList(response?.data?.data);
        setSharingFormData({
          category_id: "1",
          our_commission: response?.data?.data[0].our_commission,
          driver_commission: response?.data?.data[0].driver_commission,
          is_editable:false
        
        })
      }
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    handleGetCommissionListFunc()
  }, [])
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Pricing & Cities" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
          navBg="#F3E638"
          divideRowClass="col-xl-6 col-lg-6 col-md-6 col-6"
          selectedItem="Commission"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          isItemMoreThen8={true}
        />
        {/* top nav ended  */}

        {/* commission main div started */}
        <div className="categoriesMainDiv d-flex justify-content-between w-100 ">
          <div className="categoriesBox me-2 p-4" style={{ background: "#62929E" }}>
            <div className="categoryHeadingDiv">
              <h1>Sharing</h1>
              <h1>Ride</h1>
            </div>
            <div className="commissionForm">
              <label>MieRide Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input
                  className=""
                  placeholder="Enter Here"
                  value={sharingFormData?.our_commission}
                  onChange={(e) => setSharingFormData({ ...sharingFormData, our_commission: e.target.value,driver_commission : 100 - e.target.value  })}
                  readOnly={!sharingFormData.is_editable}
                />
                <p className="mb-0">%</p>
              </div>
              <label>Driver Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput" style={{background:"#C3C4C5"}}>
                <input
                  className=""
                  placeholder="Enter Here"
                  value={sharingFormData?.driver_commission}
                  onChange={(e) => setSharingFormData({ ...sharingFormData, driver_commission: e.target.value })}
                  readOnly={true}
                  style={{background:"#C3C4C5"}}
                />
                <p className="mb-0">%</p>
              </div>
              <div className="d-flex justify-content-center commissionStatusBox">
                <p className="mb-0">Status : </p>
                <span className="mb-0">Live</span>
              </div>
              <div className="commissionBtnGroup">
                <div className="">
                  <button className="" onClick={()=>{setSharingFormData({...sharingFormData, is_editable: true}); toast.success("Start Editing the form")}}>Edit</button>
                </div>
                <div>
                  {sharingFormData.driver_commission && sharingFormData?.our_commission  && sharingFormData.is_editable ? (
                    <button className="" style={{ background: "#139F01" }} onClick={handleAddCommission}>
                      Save
                    </button>
                  ) : (
                    <button className="" style={{ background: "#139F01", opacity: "0.5" }}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="categoriesBox me-2 p-4" style={{ background: "#546A7B",opacity:"0.3" }}>
            <div className="categoryHeadingDiv">
              <h1>Personal</h1>
              <h1>Ride</h1>
            </div>
            <div className="commissionForm">
              <label>MieRide Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <label>Driver Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <div className="d-flex justify-content-center commissionStatusBox">
                <p className="mb-0">Status : </p>
                <span className="mb-0">Live</span>
              </div>
              <div className="commissionBtnGroup">
                <div className="">
                  <button className="">Edit</button>
                </div>
                <div>
                  <button
                    className=""
                    style={{ background: "#139F01", opacity: "0.8" }}
                    onClick={() => alert("Comising Soon")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="categoriesBox me-2 p-4" style={{ background: "#C6C5B9",opacity:"0.3" }}>
            <div className="categoryHeadingDiv">
              <h1 style={{ color: "#393d3f" }}>Airport</h1>
            </div>
            <div className="commissionForm">
              <label>MieRide Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <label>Driver Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <div className="d-flex justify-content-center commissionStatusBox">
                <p className="mb-0">Status : </p>
                <span className="mb-0">Live</span>
              </div>
              <div className="commissionBtnGroup">
                <div className="">
                  <button className="">Edit</button>
                </div>
                <div>
                  <button
                    className=""
                    style={{ background: "#139F01", opacity: "0.8" }}
                    onClick={() => alert("Comising Soon")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="categoriesBox me-2 p-4" style={{ background: "#393D3F",opacity:"0.3" }}>
            <div className="categoryHeadingDiv">
              <h1>Drive</h1>
              <h1>Test</h1>
            </div>
            <div className="commissionForm">
              <label>MieRide Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <label>Driver Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <div className="d-flex justify-content-center commissionStatusBox">
                <p className="mb-0">Status : </p>
                <span className="mb-0">Live</span>
              </div>
              <div className="commissionBtnGroup">
                <div className="">
                  <button className="">Edit</button>
                </div>
                <div>
                  <button
                    className=""
                    style={{ background: "#139F01", opacity: "0.8" }}
                    onClick={() => alert("Comising Soon")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="categoriesBox me-2 p-4" style={{ background: "#F7C1BB",opacity:"0.3" }}>
            <div className="categoryHeadingDiv">
              <h1 style={{ color: "#000" }}>Intercity</h1>
            </div>
            <div className="commissionForm">
              <label>MieRide Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <label>Driver Commission</label>
              <div className="d-flex justify-content-between align-items-center commissionInput">
                <input className="" placeholder="Enter Here" />
                <p className="mb-0">%</p>
              </div>
              <div className="d-flex justify-content-center commissionStatusBox">
                <p className="mb-0">Status : </p>
                <span className="mb-0">Live</span>
              </div>
              <div className="commissionBtnGroup">
                <div className="">
                  <button className="">Edit</button>
                </div>
                <div>
                  <button
                    className=""
                    style={{ background: "#139F01", opacity: "0.8" }}
                    onClick={() => alert("Comising Soon")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* commission main div end */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default PricingCommission;
