import React from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
function PersonalLocation() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Categories",
      path: "/pricing-categories",
    },
    {
      name: "Province",
      path: "/pricing-province",
    },
    {
      name: "Location",
      path: "/pricing-sharing-location",
    },
    {
      name: "Surges",
      path: "/pricing-sharing-surges",
    },
    {
      name: "Commission",
      path: "/pricing-commission",
    },
    
    {
      name: "Interac Id",
      path: "/pricing-iterac-id",
    },
    {
      name: "Payout Info",
      path: "/pricing-payout-info",
    },
    {
      name: "Cancel",
      path: "/pricing-cancel",
    },
  ];
  const tableNav = [
    {
      name: "Sharing",
      path: "/pricing-sharing-location",
    },
    {
      name: "Personal",
      path: "/pricing-personal-location",
    },
    {
      name: "To Airport",
      path: "/pricing-to-airport",
    },
    {
      name: "From Airport",
      path: "/pricing-from-airport",
    },
    {
      name: "Drive Test",
      path: "/pricing-drive-test",
    },
    {
      name: "Intercity",
      path: "/pricing-intercity",
    },
  ];
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Pricing & Cities" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#035791"
          divideRowClass="col-xl-6 col-lg-6 col-md-6 col-6"
          selectedItem="Location"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          isItemMoreThen8={true}
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Personal" sectedItemBg="#F3F3F3" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#F3F3F3" }}>
          <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-8 row m-0 p-0">
                <div className="col-lg-4  m-0 ">
                  <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <input placeholder="Search Source" style={{width:"100%"}}/>
                    <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                  </div>
                </div>
                <div className="col-lg-4 m-0">
                  <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <input placeholder="Search Destination"  style={{width:"100%"}}/>
                    <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                  </div>
                </div>
                <div className="col-lg-3 m-0 ">
                  <div className="d-flex justify-content-between align-items-center locationSearchBtn">
                    <p className="mb-0 me-2">$</p>
                    <input placeholder="Enter Price" style={{width:"100%"}} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 row m-0 p-0">
                <div className="col-lg-6  m-0 ">
                  <div className="d-flex justify-content-end align-items-center ">
                    <div className="customSelect d-flex justify-content-center align-items-center">
                      <img src="https://cdn-icons-png.flaticon.com/128/447/447147.png" />
                    </div>
                    <button className="btn btn-success  bgSuccess" style={{ border: "none", background: "#000000" }}>
                      Swap
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 m-0 ">
                  <div className="d-flex justify-content-between align-items-center ">
                    <button className="btn btn-success w-100 bgSuccess" style={{ border: "none" }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="provienceListMain">
            <div className="row m-0 p-0">
             <div className="row col-6 p-0 m-0 ">
             {[1, 2, 3, 4, 5, 6, 7, 8]?.map((v, i) => {
               return (
                 <div className="col-3">
                   <div className="provienceItem d-flex justify-content-center">
                    <div className="text-center">
                    <img src="https://cdn-icons-png.flaticon.com/128/535/535188.png"/>
                    <p className="my-1">Brompton</p>
                    <p className="mb-0">50</p>
                      </div>
                   </div>
                 </div>
               );
             })}
             
             </div>
             <div className="row col-6 p-0 m-0">
             {[1, 2, 3, 4, 5, 6, 7, 8]?.map((v, i) => {
               return (
                 <div className="col-3">
                   <div className="provienceItem d-flex justify-content-center">
                    <div className="text-center">
                    <img src="https://cdn-icons-png.flaticon.com/128/535/535188.png"/>
                    <p className="my-1">Brompton</p>
                    <p className="mb-0">50</p>
                      </div>
                   </div>
                 </div>
               );
             })}
             
             </div>
           </div>
            </div>
            
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">Source</th>
                    <th scope="col">Destination</th>
                    <th scope="col">4 Seater Price</th>
                    <th scope="col">6 Seater Price</th>

                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((v, i) => {
                    return (
                      <tr>
                        <td scope="row">{i + 1}</td>
                        <td>Sri Mukhtsar Sahib</td>
                        <td>Chandigarh</td>

                        <td>$20</td>
                        <td>$20</td>

                        <td className="d-flex justify-content-center align-items-center">
                          <select>
                            <option>Edit</option>
                            <option>Delete</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default PersonalLocation;
