import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalProvider";
import { Tooltip } from "react-tooltip";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {getNotificationListServ} from "../services/notification.services"
function Sidebar({ selectedItem }) {
  const { globalState, setGlobalState } = useGlobalState();
  const navigate = useNavigate();

  const navItems = [
    {
      title: "Dashboard",
      img: "/icons/sidebarIcons/dashboard.png",
      path: "/",
    },

    {
      title: "Command Center",
      img: "/icons/sidebarIcons/commandCenter.png",
      path: "/create-role",
    },
    {
      title: "Booking Dashboard",
      img: "/icons/sidebarIcons/appointment.png",
      path: "/sharing-group-booking",
    },
    {
      title: "Chat Support",
      img: "/icons/sidebarIcons/liveChat.png",
      path: "/user-chat-support",
    },
    {
      title: "Funds Management",
      img: "/icons/sidebarIcons/fundsBag.png",
      path: "/user-interac-deposite",
    },

    {
      title: "Ad Control Panel",
      img: "/icons/sidebarIcons/addControl.png",
      path: "/ads-user-panel",
    },
    {
      title: "Pricing & Cities",
      img: "/icons/sidebarIcons/pricingAndCity.png",
      path: "/pricing-categories",
    },
    {
      title: "User",
      img: "/icons/sidebarIcons/user.png",
      path: "/user-list",
    },
    {
      title: "Driver",
      img: "/icons/sidebarIcons/driver.png",
      path: "/driver-list",
    },
    {
      title: "Reports",
      img: "/icons/sidebarIcons/googleDoc.png",
      path: "/report",
    },
    // {
    //   title: "Commission",
    //   img: "/icons/sidebarIcons/commission.png",
    //   path: "/commission",
    // },
    {
      title: "Support",
      img: "/icons/sidebarIcons/customerSupport.png",
      path: "/support-faq-user",
    },
  ];
  const handleGetNotificationFunc = async ()=>{
    try {
      let response = await getNotificationListServ({notifiable_type:""})
      if(response?.data?.statusCode=="200"){
        setGlobalState({...globalState, notificationList:response?.data?.data})
      }
      else{
        setGlobalState({...globalState, notificationList:0})
      }
    } catch (error) {
      setGlobalState({...globalState, notificationList:0})
    }
  }
  useEffect(()=>{
    handleGetNotificationFunc()
  }, [])
  return (
    <div className={globalState?.isFillSidebarWidth100 ? " sidebarMain" : " miniSideBar"}>
      <div
        className={
          " d-flex  mb-2  w-100 " +
          (globalState?.isFillSidebarWidth100 ? " justify-content-end" : " justify-content-center")
        }
      >
        <div className="me-1">
        
        <div style={{height:"40px", width:"40px", background:selectedItem=="Notification"? "#139F01": "#363535",borderRadius:"50%", top:"-5px", position:"relative", }}></div>
        <div onClick={()=>globalState?.notificationList?.length && navigate("/user-notification")}  style={{zIndex:"1000", position:"relative",left:"8px", top:"-40px", marginBottom:"-40px"}}>
          <img
            className="me-3"
            style={{ height: "22px", width: "22px", filter: "brightness(0) invert(1)" }}
            src="https://cdn-icons-png.flaticon.com/128/2645/2645890.png"
          />
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "#FB0003",
              borderRadius: "50%",
              height: "14px",
              width: "14px",
              fontSize: "10px",
              color: "white",
              position: "relative",
              top: "-8px",
              left: "10px",
            }}
          >
            <p className="mb-0">{globalState?.notificationList?.length}</p>
          </div>
        </div>
        </div>
        
        <i
          className="fa fa-bars text-light"
          style={{ fontSize: "22px" }}
          onClick={() =>
            setGlobalState({
              ...globalState,
              isFillSidebarWidth100: !globalState.isFillSidebarWidth100,
            })
          }
        ></i>
      </div>
      <div className="d-flex justify-content-center align-items-center  logoGroup">
        <div>
          <img
            src="/icons/sidebarIcons/brandLogo.png"
            className="mx-2"
            style={{
              height: globalState?.isFillSidebarWidth100 ? " 100px" : " 60px",
              marginBottom: globalState?.isFillSidebarWidth100 ? " 0px" : " -35px",
            }}
          />
        </div>

        {globalState?.isFillSidebarWidth100 && <img src="/icons/sidebarIcons/mie_ride.png" className="mx-2" />}
      </div>
      <div className="navItemsContainer">
        {navItems?.map((v, i) => {
          return (
            <div
              onClick={() => navigate(v?.path)}
              className="d-flex align-items-center sideNavItem"
              style={{ background: selectedItem == v?.title ? " #139F01" : "" }}
            >
              {globalState?.isFillSidebarWidth100 ? (
                <img src={v?.img} />
              ) : (
                // <img src={v?.img} data-tooltip-id="my-tooltip" data-tooltip-content={v?.title.length >11 ? v?.title?.substring(0, 8) + "..." :v?.title} />
                <Tippy
                  style={{ background: "red" }}
                  content={
                    <span style={{ color: "#139F01", fontFamily: "poppins", borderRadius: "6px", padding: "2px 4px" }}>
                      {v?.title}
                    </span>
                  }
                  placement="top"
                  theme="custom-tooltip"
                >
                  <img src={v?.img} alt="Icon" />
                </Tippy>
              )}

              {globalState?.isFillSidebarWidth100 && <p className="mb-0">{v?.title}</p>}
            </div>
          );
        })}
      </div>
      <Tooltip id="my-tooltip" className="custom-tooltip" />
    </div>
  );
}

export default Sidebar;
